import axios from 'axios'

export const BookingService = {
  async createbooking(url, data) {
    return await axios.post(url,data)
  },
  async getbookings(url){
    return await axios.get(url)
  }
}
