<!-- src/components/FilterModal.vue -->
<template>
  <div class="modal" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <h2>Add Filters</h2>
      <div v-if="!showSelection">
        <ul class="filter-options">
          <li @click="selectFilter('brands')"
            :class="{ active: filterStore.selectedFilters.brands.length || localFilters.brands.length }">Brands</li>
          <li @click="selectFilter('product')"
            :class="{ active: filterStore.selectedFilters.product.length || localFilters.product.length }">Products</li>
          <li @click="selectFilter('colors')"
            :class="{ active: filterStore.selectedFilters.colors.length || localFilters.colors.length }">Color</li>
          <li @click="selectFilter('events')"
            :class="{ active: filterStore.selectedFilters.events.length || localFilters.events.length }">Event</li>
          <li @click="selectFilter('gender')"
            :class="{ active: filterStore.selectedFilters.gender.length || localFilters.gender.length }">Gender</li>
        </ul>
      </div>
      <div v-else>
        <FilterSelection :filterType="selectedFilter" :options="getOptions(selectedFilter)" :onSelect="handleSelection"
          :onCancel="cancelSelection" />
      </div>
      <div class="modal-actions" v-if="!showSelection">
        <button @click="applyFilters" class="btn-apply">Apply</button>
        <button @click="cancelFilters">Clear Filters</button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps } from 'vue';
import { useFilterStore } from '@/stores/filterstore.js';
import FilterSelection from '@/components/FilterSelection.vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
});
const filterStore = useFilterStore();


const showSelection = ref(false);
const selectedFilter = ref(null);
const localFilters = ref({
  brands: [],
  product: [],
  colors: [],
  events: [],
  gender: [],
});

// Store the previous filter selections for resetting if necessary
const previousFilters = ref({
  brands: [],
  product: [],
  colors: [],
  events: [],
  gender: [],
});

// Example options for each filter
const filterOptions = {
  Brand: ['Brand A', 'Brand B', 'Brand C'],
  Product: ['Product A', 'Product B', 'Product C'],
  Colors: ['Red', 'Green', 'Blue'],
  Events: ['Event A', 'Event B', 'Event C'],
  Gender: ['Men', 'Women', 'Unisex'],
};

const getmailfilters = () => {
  if (filterStore.mainFilters.value == undefined) {
    const itemStr = localStorage.getItem("commondata");
    filterStore.mainFilters.value = JSON.parse(itemStr)['value'];
  }
}

// Function to get options based on selected filter type
const getOptions = (filterType) => {
  if(filterType == "gender")
    return filterOptions.Gender;

  getmailfilters();
  var a = filterStore.mainFilters.value[filterType];
  return filterStore.mainFilters.value[filterType] || [];
};

// Function to handle filter selection
const handleSelection = (selected) => {
  const currentFilterType = selectedFilter.value.toLowerCase();

  // Merge the selected options with existing local filter state
  localFilters.value[currentFilterType] = [
    ...new Set([...localFilters.value[currentFilterType], ...selected]),
  ];

  showSelection.value = false;  // Close selection modal
};

// Function to select filter
const selectFilter = (filter) => {
  selectedFilter.value = filter;
  showSelection.value = true;
};

// Cancel selection and return to filter options
const cancelSelection = () => {
  showSelection.value = false;
};

const applyFilters = () => {
  filterStore.applyFilters(localFilters.value);
  props.closeModal();
};

// Cancel and clear the filter selections
const cancelFilters = () => {
  localFilters.value = { ...previousFilters.value };  // Reset local filters
  filterStore.clearFilters();  // Clear Pinia store filters
  props.closeModal();  // Close the modal
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

.filter-options {
  list-style: none;
  padding: 0;
}

.filter-options li {
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-options li:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.modal-actions {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.modal-actions button{
  margin-bottom: 10px;
  border-radius: 50px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  background-color: white !important;
}

.btn-apply {
  background-color: black !important;
  color: white;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}


.filter-options li.active {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}
</style>