<template>
  <div class="nav-container">
    <ul class="nav nav-pills my-3">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <a
          :class="['nav-link-mw', { active: index === activeTab }]"
          href="#"
          @click="setActiveTab(index)"
        >
          {{ tab }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

// Example tabs for the navigation
const tabs = ["My Cart", "Track Orders", "Shopping History", "Returns"];
const activeTab = ref(0);
const emit = defineEmits(["update:modelValue"]);

const setActiveTab = (index) => {
  activeTab.value = index;
  emit("update:modelValue", activeTab.value);
};
</script>

<style scoped>
/* Container for horizontal scrolling */
.nav-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer */
  height: 13%;
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.nav-container::-webkit-scrollbar {
  display: none;
}

/* Flex styling to keep items in a single row */
.nav {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  justify-content: flex-start;
  gap: 10px;
  padding: 0 15px;
}

/* Prevent shrinking and maintain fixed width for each item */
.nav-item {
  flex: 0 0 auto;
  list-style: none;
  text-decoration: none !important;
}
.nav-item a {
  text-decoration: none !important;
}

/* Black and white styling for links */
.nav-link-mw {
  color: #000;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #c4c4c494;
  text-align: center;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Active state */
.nav-link-mw.active {
  background-color: #000;
  color: #fff;
}

/* Mobile-first styling with larger padding on larger screens */
@media (min-width: 768px) {
  .nav-link {
    padding: 10px 30px;
  }
}
</style>
