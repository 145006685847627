<!-- src/components/FilterOptions.vue -->
<template>
  <div class="filter-options">
    <button
      class="btn filter-button me-2"
      style="border-radius: 50px; width: 40px; height: 40px;padding: 0px;"
      @click="showModal('any')"
    >
      <i class="fa-solid fa-filter"></i>
      <!-- Bootstrap Icons for filter icon -->
    </button>
    <button
      class="btn filter-button me-2"
      @click="showModal('brand')"
      :class="{ active: filterStore.selectedFilters.brands.length }"
    >
      Brand
    </button>
    <button
      class="btn filter-button me-2"
      @click="showModal('product')"
      :class="{ active: filterStore.selectedFilters.product.length }"
    >
      Product
    </button>
    <button
      class="btn filter-button me-2"
      @click="showModal('gender')"
      :class="{ active: filterStore.selectedFilters.gender.length }"
    >
      Gender
    </button>
    <button
      class="btn filter-button me-2"
      @click="showModal('color')"
      :class="{ active: filterStore.selectedFilters.colors.length }"
    >
      Color
    </button>
    <button
      class="btn filter-button"
      @click="showModal('event')"
      :class="{ active: filterStore.selectedFilters.events.length }"
    >
      Events
    </button>

    <FilterModal :isVisible="isModalVisible" :closeModal="closeModal" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFilterStore } from "@/stores/filterstore.js";
import FilterModal from "@/components/FilterModal.vue";

const filterStore = useFilterStore();
const isModalVisible = ref(false);

const showModal = (filterType) => {
  let a = filterType.name;
  isModalVisible.value = a;
  isModalVisible.value = true;
};

const closeModal = () => {
  isModalVisible.value = false;
};
</script>

<style scoped>
.filter-options {
  /* Flex container for horizontal alignment */
  padding-top: 5px;
  padding-bottom: 10px;
  overflow-x: auto;
  /* Enable horizontal scrolling if needed */
  white-space: nowrap;
  /* Prevent line breaks */
}

.filter-button {
  color: #000;
    padding: 8px 20px;
    border-radius: 50px;
    background-color: #e9e9e98c;
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
  /* color: #000;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #f9f9f9;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  color: black;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid rgb(130, 130, 130);*/
} 

.filter-button.active {
  background-color: black;
  color: white;
}

.filter-button:hover {
  background-color: black;
  color: white;
}
</style>
