<!-- VendorPage.vue -->
<template>
  <FullScreenLoader :isVisible="loading" />
  <div class="vendor-page">
    <TopButtons :buttons="['backbtn', 'image']" :options="topvarOptions" />

    <!-- Second row: info icon and search bar -->
    <div class="search-container">
      <input
        type="text"
        v-model="searchQuery"
        class="search-input"
        placeholder="Search"
        @keyup.enter="triggerSearch"
      />
      <button class="camera-icon-btn" @click="triggerSearch">
        <i class="fas fa-search camera-icon"></i>
      </button>
    </div>

    <!-- Third row: Latest product list (horizontal scrollable) -->
    <div class="latest-products mx-3">
      <h3>Latest Products</h3>
      <div class="product-carousel">
        <ProductCardSmall
          v-for="lat in latest"
          :temp="true"
          v-bind:key="lat"
          :product="lat"
          @add-to-collection="() => {}"
        />
      </div>
    </div>

    <!-- Third row: Latest product list (horizontal scrollable) -->
    <div class="latest-products mx-3">
      <h3>Best Sellers</h3>
      <div class="product-carousel">
        <ProductCardSmall
          v-for="lat in bestsell"
          :temp="true"
          v-bind:key="lat"
          :product="lat"
          @add-to-collection="() => {}"
        />
      </div>
    </div>

    <!-- Fifth row: Regular product list (two-column grid) -->
    <div class="regular-products mx-3">
      <h3>All Products</h3>

      <ProductGrid
        :products="filteredProducts"
        :loading="loading"
        :isSmall="true"
        @load-more="loadMoreProducts"
      />
      
    </div>
  </div>
</template>

<script setup>
import TopButtons from "@/components/common/TopButtons.vue";
import ProductCardSmall from "@/components/ProductCardSmall.vue";
import ProductGrid from "@/components/ProductGrid.vue";
import { URLService } from "@/services/_url";
import { VendorService } from "@/services/vendorservice";
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFilterStore } from "@/stores/filterstore.js";
import { ProductService } from "@/services/productservice";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";

const filterStore = useFilterStore();
const vendor = ref({
  logo: "path_to_vendor_logo",
  name: "Vendor Name",
});
const topvarOptions = ref({ image: vendor.value.logo_url, name: '' });
const loaded = ref(false);
const latest = ref([]);
const bestsell = ref([]);
const vendorid = ref(0);

const route = useRoute();
const searchQuery = ref("");

const triggerSearch = () => {
  getfilterProducts();
};

const getfilterProducts = () => {
  page.value = -1;
  loadMoreProducts();
};

const products = ref([]);
onMounted(async () => {
  let id = route.params.id;
  if (id == undefined || id == null) {
    router.go(-1);
  }
  vendorid.value = id;
  var url = URLService.getvendor.replace(":id", id);
  try {
    var response = await VendorService.getvendor(url);
    loading.value = true;
    if (response.status == 200) {
      if (response.data.data) {
        vendor.value = response.data.data;
        topvarOptions.value.image = vendor.value.logo_url;
        topvarOptions.value.name = vendor.value.name;
        latest.value = response.data.data.latest_products;
        bestsell.value = response.data.data.bestsell_products;
        products.value = response.data.all;
      }
      loaded.value = true;
      loading.value = false;
    } else {
      loading.value = false;
      router.go(-1);
    }
  } catch {
    loading.value = false;
  }
});

const router = useRouter();

const loading = ref(true);
const page = ref(1);

const loadMoreProducts = async () => {
  if (loading.value) return; // Prevent loading if already loading
  loading.value = true; // Set loading state

  page.value = page.value + 1;
  filterStore.selectedFilters.page = page.value;
  filterStore.selectedFilters.brands = [vendorid.value];
  let filters = JSON.parse(JSON.stringify(filterStore.selectedFilters));
  filters.searchterm = searchQuery.value;
  let data = await ProductService.getProductList(
    URLService.getProducts,
    filters
  );

  if (data.status == 200) {
    if (page.value == 0) products.value = data.data;
    else products.value.push(...data.data);
  } else products.value.push(...[]);

  loading.value = false;
};

const filteredProducts = computed(() => {
  return products.value;
});
</script>

<style scoped>
.vendor-page {
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.vendor-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
}

.back-button {
  position: absolute;
  left: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.vendor-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.vendor-search {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.vendor-search i {
  margin-right: 1rem;
}

.vendor-search input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-carousel {
  display: flex;
  overflow-x: auto; /* Use 'auto' for scroll only when needed */
  gap: 1rem;
  padding-bottom: 10px; /* Extra space to prevent scrollbar overlap */
  scroll-behavior: smooth; /* Optional for smooth scrolling */
}

.product-carousel::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.product-carousel::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.product-item {
  min-width: 150px;
}

.search-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 20px 10px;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 8px 35px 8px 15px;
  /* padding for the camera icon */
  border-radius: 50px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.camera-icon-btn {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.camera-icon {
  font-size: 18px;
  color: #666;
}


.vendor-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.vendor-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
