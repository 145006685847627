<template>
  <div class="mx-2">
    <div class="stock-selections">
      <!-- Location dropdown with mobile responsiveness -->
      <!-- <div class="location-select">
        <label for="location" :class="{ 'text-danger': (!location && cartStore.issubmitted) }">Location</label>
        <select v-model="location" id="location" class="rounded-dropdown">
          <option v-for="_loc in locations" :value="_loc.id" :key="_loc.id"> {{ _loc.name }}</option>
        </select>
      </div> -->
      <!-- Color scrollable buttons with actual colors -->
      <!-- <div class="color-options">
        <label :class="{ 'text-danger': (!color && cartStore.issubmitted) }">Color</label>
        <div class="color-buttons-wrapper">
          <div v-for="colorOption in colors" :key="colorOption.name" @click="selectColor(colorOption.name)"
            :class="['color-btn', { disabled: !availableColors.includes(colorOption.name) }, { selected: color === colorOption.name }]"
            :style="{ backgroundColor: colorOption.color }"></div>
            <span v-if="colors == undefined || colors.length == 0">No Stocks Available</span>
        </div>
      </div> -->
      <!-- Size scrollable buttons -->
      <div class="size-options">
        <label :class="{ 'text-danger': !size && cartStore.issubmitted }"
          >Size</label
        >
        <div class="scrollable-buttons">
          <button
            v-for="sizeOption in sizes"
            :key="sizeOption"
            @click="onSelectSize(sizeOption)"
            :class="[
              'size-btn',
              { disabled: color != '' && !availableSizes.includes(sizeOption) },
              { selected: size === sizeOption },
            ]"
          >
            {{ sizeOption }}
          </button>
          <span v-if="sizes == undefined || sizes?.length == 0"
            >No Stocks Available</span
          >
        </div>
      </div>

      <!-- Quantity input with buttons as one component -->
      <div class="quantity-container">
        <label class="quantity-label">Quantity</label>
        <div class="quantity-input">
          <button
            @click="quantity = Math.max(1, quantity - 1)"
            class="qty-btn qty-left"
          >
            -
          </button>
          <span class="qty-display"
            ><input v-model="quantity" type="number" min="1" class="qty-input" :class="quantity == 1 ? 'disabled': ''"
          /></span>
          <button @click="quantity++" class="qty-btn qty-right">+</button>
        </div>
      </div>

      <!-- <div class="quantity">
        <label>Quantity</label>
        <div class="quantity-input">
          <button
            @click="quantity = Math.max(1, quantity - 1)"
            class="qty-btn qty-left"
          >
            -
          </button>
          <input v-model="quantity" type="number" min="1" class="qty-input" />
          <button @click="quantity++" class="qty-btn qty-right">+</button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { useCartStore } from "@/stores/cartStore";

const cartStore = useCartStore();
const props = defineProps({
  product: Object,
  selectedLocation: Object,
  selectedcolor: String,
  selectedSize: String,
  selectedQuantity: Number,
});

// const location = ref("");
const color = ref("");
const size = ref("");
const quantity = ref(1);
// const locations = ref([]);
const locationsChange = ref({});
const sizes = ref([]);
// const availableColors = ref([]);
const availableSizes = ref([]);
// const stocks = ref([]);

const emit = defineEmits([
  "update:selectedLocation",
  "update:selectedsize",
  "update:selectedcolor",
  "update:selectedquantity",
]);

// Define color options with actual color hex values
// const colors = ref([]);

onMounted(() => {
  locationsChange.value = props.product;
  // calculatestocks(props.product)
  cartStore.setissubmittedfalse();
});

// const calculatestocks = values => {
//   console.log(values) 
//   console.log(props.selectedColor)
// }

const onSelectSize = (_size) => {
  size.value = _size;
};

watch(
  () => props.selectedcolor,
  (val) => {
    let lines = props.product.lines;
    let filterLines = lines.filter((x) => {
      return x.color == val;
    });
    if (filterLines.length > 0) {
      color.value = val;
      let stocks = filterLines[0].stocks;
      sizes.value = stocks.map((x) => x.size);
      availableSizes.value = stocks
        .filter((x) => {
          return x.stockstatus.toLowerCase() == "in";
        })
        .map((x) => x.size);
    }
  }
);

watch(quantity, (val, old) => {
  if (val <= 0) {
    if (val * -1 == old) {
      quantity.value = old;
    } else {
      quantity.value = 1;
    }
  }
});

watch(size, (val) => {
  emit("update:selectedsize", val);
});

watch(quantity, (val) => {
  emit("update:selectedquantity", val);
});

// const selectColor = (name) => {
//   color.value = name
//   size.value = '';

//   if (stocks.value?.length > 0) {
//     availableSizes.value = sizes.value.filter((size) =>
//       stocks.value.some((stock) => stock.size === size && stock.color == color.value && stock.stock > 0 && stock.location_id == location.value)
//     ).map(x => x);
//   }
// }

// watch(location, val => {
//   color.value = '';
//   size.value = '';
//   availableColors.value = []
//   availableSizes.value = []

//   if (stocks.value.length > 0) {
//     availableColors.value = colors.value.filter((color) =>
//       stocks.value.some((stock) => stock.color === color.name && stock.stock > 0 && stock.location_id == val)
//     ).map(x => x.name);
//   }
// });

// watch(locationsChange, val => {
//   if (val?.locations?.length > 0) {
//     let locs = JSON.parse(JSON.stringify(val.locations));
//     locations.value = locs;
//     location.value = locs[0].id;
//     color.value = '';
//     availableColors.value = []
//     availableSizes.value = []

//     let product = JSON.parse(JSON.stringify(val))

//     colors.value = product.actual_colors
//     sizes.value = product.size_chart?.split(',').filter(x => { return x != undefined && x.trimEnd() != "" });
//     stocks.value = product?.product_stocks;
//     if (product?.product_stocks?.length > 0) {
//       availableColors.value = colors.value.filter((color) =>
//         product.product_stocks.some((stock) => stock.color === color.name && stock.stock > 0 && stock.location_id == location.value)
//       ).map(x => x.name);
//     }
//   }
// });

// watch(location, (val) => {
//   let selectedLoc = locations.value.filter(x => { return x.id == val });
//   if (selectedLoc.length > 0)
//     emit('update:selectedLocation', selectedLoc[0]);
// });

// watch(color, (val) => {
//   emit('update:selectedcolor', val);
// });
</script>

<style scoped>
/* General stock and selections container */
.stock-selections {
  padding: 10px;
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Labels */
.stock-selections label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

/* Location dropdown */
.rounded-dropdown {
  padding: 10px;
  border-radius: 30px;
  border: 2px solid black;
  background-color: white;
  color: black;
  width: 100%;
}

/* Make the dropdown full width on mobile */
@media (max-width: 576px) {
  .rounded-dropdown {
    width: 100%;
  }
}

/* Scrollable buttons container */

.scrollable-buttons {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  scrollbar-width: none;
}

.scrollable-buttons::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar in Chrome, Safari */
}

/* Size buttons */
.size-btn {
  padding: 10px 20px;
  border-radius: 30px;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  /* For overlay positioning */
  transition: opacity 0.3s ease;
  opacity: 1;
}

.size-btn.selected {
  border: 2px solid black;
  background-color: black;
  color: white;
}

.size-btn:hover {
  background-color: black;
  color: white;
}

/* Wrapper for horizontal scrolling of color buttons */
.color-buttons-wrapper {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scrollbar-width: none;
  /* Hides scrollbar in Firefox */
}

.color-buttons-wrapper::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar in Chrome, Safari */
}

/* Color buttons styled as divs */
.color-btn {
  width: 60px;
  /* Set fixed width */
  height: 60px;
  /* Set fixed height */
  border-radius: 50%;
  border: 1px solid rgb(121, 120, 120);
  cursor: pointer;
  flex-shrink: 0;
  /* Prevent shrinking of the buttons */
  opacity: 0.7;
  position: relative;
  /* For overlay positioning */
  transition: opacity 0.3s ease;
}

.color-btn.selected {
  border: 4px solid black;
  opacity: 1;
}

/* Quantity input and buttons as one component */
.quantity-container {
  display: flex;
  justify-content: space-between; /* Align label to left and controls to right */
  align-items: center;
  width: 100%;
}

.quantity-label {
  font-weight: bold;
  margin-right: 10px;
}

.quantity-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-btn {
  background-color: #e0e0e0; /* Light gray for button background */
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-display {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.quantity-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-btn {
  background-color: white;
  padding: 10px;
  cursor: pointer;
}

.qty-input {
  border: none;
  width: 30px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  box-shadow: none; /* Remove shadow */
  outline: none; /* Remove outline */
}
.qty-input:focus {
  outline: none; /* Ensure no outline on focus */
  box-shadow: none; /* Ensure no shadow on focus */
}
.qty-input::-moz-focus-inner {
  border: none;
}

.qty-left,
.qty-right {
  border-radius: 50px;
  background-color: #e0e0e0;
  font-weight: 800;
}

.text-danger {
  color: red;
}

/* Disabled state */
.color-btn.disabled {
  cursor: not-allowed;
  /* Change cursor to indicate unavailability */
  opacity: 0.3;
  pointer-events: none;
  /* Disable click events */
}

/* Diagonal line overlay when disabled */
.color-btn.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 8px,
    rgb(255, 255, 255) 8px,
    rgb(255, 255, 255) 10px
  );
  border-radius: 50%;
  /* Keep it circular */
  pointer-events: none;
  /* Prevent interfering with other events */
}

/* Disabled state */
.size-btn.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}

/* Diagonal line overlay */
.size-btn.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 8px,
    rgba(0, 0, 0, 0.6) 8px,
    rgba(0, 0, 0, 0.6) 10px
  );
  border-radius: 30px;
  /* Match the button shape */
  pointer-events: none;
  /* Prevent interfering with events */
}
</style>
