// src/stores/userStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVendorStore = defineStore('vendor', () => {
  const policies = ref([]);

  
  const setpolicies = (data) => {
    policies.value = data;
  }

  return { policies, setpolicies };
});
