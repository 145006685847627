<template>
  <div>
    <iframe
      ref="paymentIframe"
      :src="paymentUrl"
      width="100%"
      style="height: 100vh"
      frameborder="0"
      @load="checkIframePage"
    ></iframe>
  </div>
</template>

<script setup>
// import { URLService } from "@/services/_url";
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router"; // Use Vue Router to redirect.
const paymentUrl = ref("http://127.0.0.1:8000/order"); // Replace with the gateway URL.
const paymentIframe = ref(null);
const router = useRouter();
const targetBackendPage = "http://127.0.0.1:8000/success"; // Replace with your backend page URL.

const checkIframePage = () => {
  try {
    const iframeWindow = paymentIframe.value.contentWindow;
    const iframeLocation = iframeWindow.location.href;

    console.log("Current Iframe URL:", iframeLocation); // Optional: For debugging.

    // Check if the iframe page matches your backend success page.
    if (iframeLocation === targetBackendPage) {
      alert();
      console.log(router);
      //   router.push("/success"); // Redirect to the desired Vue page.
    }
  } catch (error) {
    console.warn("Cross-origin restriction:", error);
  }
};

const handleMessage = (event) => {
  if (event.data === "payment-success") {
    setTimeout(() => {
      console.log("Payment succeeded!");
      router.push({name: 'successpage'});
    }, 4000); // Redirect to success page.
  }
};

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
});

onMounted(() => {
  window.addEventListener("message", handleMessage);
});
</script>

<style scoped>
iframe {
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>
