<!-- ProductDetails.vue -->
<template>
  <div class="product-details mx-2">
    <h2>{{ product.name }}</h2>
    <p>
      <span v-if="product.discount" class="old-price price">LKR {{ product.price }}</span>
      <span class="new-price price">LKR {{ product.discount || product.price }}</span>
    </p>
    <pre class="product-description">{{ product.description }}</pre>

    <pre class="product-description" style="text-wrap: balance;">{{ featuredetails }}</pre>

    <!-- Size Guide link -->
    <a href="#" class="size-guide-btn" @click="opensizechart">Size Guide</a>
  </div>

  
  <div v-if="isModalOpen" class="modal-overlay ">
      <div class="modal-content">
        <button @click="closeModal" class="close-button">✖</button>
        <h3>Size Chart</h3>
        <div class="">
          <img :src="product.size_chart_url" style="width: 100%;"/>
        </div>
      </div>
    </div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';

const featuredetails = computed(() => {
  let str = "";
  let product_features = JSON.parse(JSON.stringify(props.product))?.product_detail;
  if (product_features?.length > 0) {
    let _features = JSON.parse(product_features[0].features);
    _features.forEach(feature => {
      str += feature.key.trimEnd() + " : " + feature.value.trimEnd() + "\r\n";
    });
  }
  return str;
});

const props = defineProps({
  product: Object,
});

const isModalOpen = ref(false)

const opensizechart = () =>{
  isModalOpen.value = !isModalOpen.value
}

const closeModal = () => {
  isModalOpen.value = !isModalOpen.value
}
</script>

<style scoped>
.product-details {
  padding: 10px;
}

.old-price {
  text-decoration: line-through;
  color: red;
}

.new-price {
  font-weight: bold;
}

.price {
  margin-right: 0.5rem;
}

.product-description {
  color: #686868;
  text-wrap: balance;
  font-family: sfpro-r !important;
}

/* Size Guide button styling */
.size-guide-btn {
  display: inline-block;
  text-align: center;
  padding: 8px 16px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 30px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  float: right;
  /* Align to the right */
}

.size-guide-btn:hover {
  background-color: black;
  color: white;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  /* White background for modal */
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  /* Max width for modal */
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>