// src/stores/FilterStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFilterStore = defineStore('filter', () => {
  const selectedFilters = ref({
    brands: [],
    product: [],
    colors: [],
    events: [],
    gender: []
  });
  const mainFilters = ref({});
  const reGetata = ref(false);
  const images = ref([]);

  const applyFilters = (filters) => {
    selectedFilters.value = JSON.parse(JSON.stringify(filters));
    reGetata.value = true;
  };

  const setMainfilterData = (data) => {
    mainFilters.value = data;
  }

  const setmainimages = (data) => {
    images.value = data.images
  }

  const clearFilters = () => {
    selectedFilters.value = {
      brands: [],
      product: [],
      colors: [],
      events: [],
      gender: [],
    };
    reGetata.value = true;
  };

  return { images, selectedFilters,mainFilters,reGetata, applyFilters, clearFilters, setMainfilterData, setmainimages };
});