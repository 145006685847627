import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import ProductList from '../pages/ProductList.vue'
import ProductPage from '@/pages/ProductPage.vue'
import VendorPage from '@/pages/VendorPage.vue'
import CollectionPage from '@/pages/CollectionPage.vue'
import AddCollectionPage from '@/pages/AddCollectionPage.vue'
import RunawayPage from '@/pages/RunawayPage.vue'
import SettingsPage from '@/pages/SettingsPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import CartPage from '@/pages/CartPage.vue'
import SplashPage from '@/pages/SplashPage.vue'
import CheckoutPage from '@/pages/CheckoutPage.vue'
import PaymentGateway from '@/pages/PaymentGateway.vue'
import SuccessPage from '@/pages/SuccessPage.vue'
import RegisterPage from '@/pages/RegisterPage.vue'
import ViewCollectionPage from '@/components/ViewCollectionPage.vue'
import AddReturn from '@/components/return/AddReturn.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'

const routes = [{
  path: '/',
  name: 'Splash',
  component: SplashPage
},
{
  path: '/home',
  name: 'Home',
  component: HomePage
},
{
  path: '/products',
  name: 'ProductList',
  component: ProductList
},
{
  path: '/vendor/:id',
  name: 'VendorPage',
  component: VendorPage,
  props: true
},
{
  path: '/collection',
  name: 'Collection',
  component: CollectionPage
},
{
  path: '/viewcollection/:id',
  name: 'ViewCollection',
  component: ViewCollectionPage
},
{
  path: '/collections/add',
  name: 'AddCollection',
  component: AddCollectionPage,
},
{
  path: '/runaway',
  name: 'Runaway',
  component: RunawayPage
},
{
  path: '/cart',
  name: 'Cart',
  component: CartPage
},
{
  path: '/productpage/:id',
  name: 'ProductPage',
  component: ProductPage
},
{
  path: '/checkout',
  name: 'CheckoutPage',
  component: CheckoutPage
},
{
  path: '/payment',
  name: 'paymentpage',
  component: PaymentGateway
},
{
  path: '/success',
  name: 'successpage',
  component: SuccessPage
},
//   {
//     path: '/search',
//     name: 'Search',
//     component: Search
//   },
//   {
//     path: '/profile',
//     name: 'Profile',
//     component: Profile
//   },
{
  path: '/settings',
  name: 'Settings',
  component: SettingsPage
},
{
  path: '/login',
  name: 'Login',
  component: LoginPage
},
{
  path: '/register',
  name: 'register',
  component: RegisterPage
},
{
  path: '/addreturn',
  name: 'addreturn',
  component: AddReturn
},
{
  path: '/forgotpassword',
  name: 'forgotpassword',
  component: ForgotPassword
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router