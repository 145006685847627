<template>
  <div class="container">
    <div class="card">
      <h1>Yay!</h1>    
      <img class="image" src="@/assets/successimg.jpg" alt="Delivery Items" />
      <p>
        Your order <span class="order-number">#13062019</span> is on the way!
      </p>
      <button class="button" @click="backToShop">
        Ok, cool! Back to shop!
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useCartStore } from "@/stores/cartStore";
import { useRouter } from "vue-router";


const router = useRouter();
const carstore = useCartStore();

onMounted(() => {
  carstore.clearcart();
})
// Redirect to shop page
function backToShop() {
  router.push({name: 'Home'});
}
</script>

<style scoped>
/* Container to center the card */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f5;
}

/* Card styling */
.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
}

/* Image styling */
.image {
  width: 100%;
  max-width: 250px;
  margin: 20px 0;
}

/* Order number styling */
.order-number {
  font-weight: bold;
}

/* Button styling */
.button {
  background-color: #000;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #333;
}
</style>
