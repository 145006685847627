<template>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner" style="border-radius: 10px !important">
      <div
        v-for="(product, index) in products"
        :key="index"
        class="carousel-item"
        :class="{ active: index === 0 }"
      >
        <div
          class="card p-3"
          style="border-radius: 15px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)"
        >
          <div class="row g-0 align-items-center">
            <!-- Product Image Column -->
            <div class="col-5">
              <div class="product-image-wrapper">
                <img
                  :src="product.image"
                  class="product-image"
                  alt="Product Image"
                />
              </div>
            </div>

            <!-- Product Details Column -->
            <div class="col-7 ps-3">
              <h5 class="mb-1 text-truncate-2">{{ product.name }}</h5>
              <p class="text-muted mb-0">
                <small>{{ product.vendor }}</small>
              </p>
              <p class="text-muted mb-1">Price: ${{ product.itemprice }}</p>
              <p class="mb-1">Size: {{ product.size }}</p>
              <p class="mb-1">Quantity: {{ product.quantity }}</p>
              <!-- <p class="text-muted">
                <i class="fa fa-shield-alt text-primary"></i>
                Covered by <a href="#">Purchase Protection Policy</a>
              </p> -->
            </div>
          </div>

          <!-- Indicator Badge -->
          <div class="position-absolute top-0 end-0 p-2">
            <span class="badge bg-dark"
              >{{ index + 1 }}/{{ products.length }}</span
            >
          </div>
        </div>

        <!-- Carousel Indicators -->
        <div class="d-flex justify-content-center mt-2">
          <span
            v-for="(product, idx) in products"
            :key="idx"
            @click="goToSlide(idx)"
            :class="['indicator-dot', { 'active-dot': idx === activeSlide }]"
          ></span>
        </div>
      </div>
    </div>

    <!-- Carousel Controls -->
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
      v-if="platform === 'web'"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>
    <button
      v-if="platform === 'web'"
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, onUnmounted } from "vue";
import { Carousel } from "bootstrap";
import { Device } from "@capacitor/device";

defineProps({
  products: Array,
});

const platform = ref(null);
onMounted(async () => {
  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }

  const carouselElement = document.getElementById("carouselExampleControls");
  // const bsCarousel = new Carousel(carouselElement);
  carouselElement.addEventListener("slide.bs.carousel", (event) => {
    activeSlide.value = event.to;
  });

  startCarousel();
});

const goToSlide = (index) => {
  const carousel = document.querySelector("#carouselExampleControls");
  const bsCarousel = Carousel.getInstance(carousel) || new Carousel(carousel);
  bsCarousel.to(index); // Navigate to the selected slide
  activeSlide.value = index;
};

const activeSlide = ref(0);

let intervalId;
const startCarousel = () => {
  intervalId = setInterval(() => {
    goToSlide(activeSlide.value + 1);
  }, 5000);
};

const stopCarousel = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
};

onUnmounted(() => {
  stopCarousel();
});
</script>

<style scoped>
.card {
  position: relative;
}

/* Product Image Wrapper */
.product-image-wrapper {
  width: 100%; /* Ensures full width of the column */
  aspect-ratio: 1 / 1; /* Keeps the image container square */
  overflow: hidden; /* Clips content that overflows */
  border-radius: 10px;
}

/* Product Image Styling */
.product-image {
  width: 100%; /* Fill the container width */
  height: 100%; /* Fill the container height */
  object-fit: cover; /* Cover the space without distortion */
  object-position: center; /* Centers the image within the container */
}

/* Truncate long product names to 2 lines */
.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Carousel Indicator Dots */
.indicator-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.active-dot {
  background-color: #000;
}
</style>

<!-- <template>
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div v-for="(product, index) in products" :key="index" class="carousel-item"
                :class="{ active: index === 0 }">
                <img :src="product.image" class="d-block w-100" :alt="product.name" />
                <div class="carousel-caption">
                    <h5>{{ product.name }}</h5>
                    <p>Price: ${{ product.price }} | Size: {{ product.size }}</p>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
    products: Array
})
</script> -->
