<template>
  <div
    class="slider-container"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <!-- Images with Peek Effect -->
    <div
      class="slider"
      :style="{
        transform: `translateX(calc(-${
          currentIndex * 100
        }% + ${swipeOffset}px))`,
      }"
    >
      <div v-for="(image, index) in images" :key="index" class="slide">
        <img
          :src="image"
          alt="Slide Image"
          class="d-block w-100 border rounded"
          style="height: 250px; object-fit: cover"
        />
      </div>
    </div>

    <!-- Navigation Dots -->
    <div class="dots">
      <span
        v-for="(image, index) in images"
        :key="'dot-' + index"
        :class="{ dot: true, active: currentIndex === index }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

// Array of images
const images = [
  "./300_400.png",
  "./300_400.png",
  "./300_400.png",
  "./300_400.png",
  "./300_400.png",
];

const currentIndex = ref(0); // Tracks the current image index
const loading = ref(false); // Shows loading indicator during transitions
const swipeOffset = ref(0); // Tracks the swipe offset for the current swipe

// Variables to store touch positions
let startX = 0;
let autoSlideInterval = null;

// Touch start event
const onTouchStart = (event) => {
  startX = event.touches[0].clientX;
  swipeOffset.value = 0; // Reset swipe offset at start
};

// Touch move event
const onTouchMove = (event) => {
  const currentX = event.touches[0].clientX;
  const deltaX = currentX - startX;

  // Apply swipe offset to show next/previous image coming in
  swipeOffset.value = deltaX * 1.5; // Adjust 0.3 factor for smoother/faster preview of next image
};

// Touch end event
const onTouchEnd = (event) => {
  const endX = event.changedTouches[0].clientX;
  const deltaX = endX - startX;

  if (loading.value) return; // Prevent swipe during loading

  loading.value = true; // Show loading overlay

  setTimeout(() => {
    if (deltaX > 50) {
      // Swipe Right - Go to previous image or loop to last image
      currentIndex.value =
        currentIndex.value > 0 ? currentIndex.value - 1 : images.length - 1;
    } else if (deltaX < -50) {
      // Swipe Left - Go to next image or loop to first image
      currentIndex.value =
        currentIndex.value < images.length - 1 ? currentIndex.value + 1 : 0;
    }
    swipeOffset.value = 0; // Reset swipe offset after swipe ends
    loading.value = false; // Hide loading overlay
    // Restart auto-slide after user interaction
    startAutoSlide();
  }, 500); // Loading delay (adjust as needed)
};

// Directly navigate to a specific slide on dot click
const goToSlide = (index) => {
  if (loading.value) return;
  loading.value = true;
  currentIndex.value = index;
  swipeOffset.value = 0;
  setTimeout(() => {
    loading.value = false;
    startAutoSlide(); // Restart auto-slide after manual slide
  }, 500); // Reset loading state after transition
};

const startAutoSlide = () => {
  if (autoSlideInterval) {
    clearInterval(autoSlideInterval); // Clear any existing interval
  }

  autoSlideInterval = setInterval(() => {
    currentIndex.value =
      currentIndex.value < images.length - 1 ? currentIndex.value + 1 : 0;
  }, 5000); // Change to next image every 3 seconds
};

// Start the auto-slide when the component is mounted
onMounted(() => {
  startAutoSlide();
});

// Cleanup the interval when the component is unmounted
onBeforeUnmount(() => {
  clearInterval(autoSlideInterval);
});
</script>

<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 250px;
  border-radius: 8px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #fff;
}
</style>
