<template>
  <!-- Bottom Navigation Bar for Mobile -->
  <nav
    class="navbar-mobile fixed-bottom d-flex justify-content-around bg-light d-md-none"
  >
    <router-link
      v-for="(item, index) in navItems"
      :key="index"
      :to="item.route"
      :id="item.name"
      class="nav-item"
    >
      <span v-if="item.name == 'My Collection'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Runaway'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Home'"
        ><img :src="getIcon(item)" alt="" width="30px" height="30px"
      /></span>
      <span v-if="item.name == 'Settings'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Cart'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>

      <div
        v-if="item.name === 'Cart' && cartStore.cart?.length > 0"
        class="badge"
      >
        <span style="opacity: 0">.</span>
      </div>
    </router-link>
  </nav>

  <!-- Centered Floating Navigation Bar for Web -->
  <div
    class="floating-navbar d-none d-md-flex justify-content-between align-items-center"
  >
    <router-link
      v-for="(item, index) in navItems"
      :key="index"
      :to="item.route"
      id="{{ item.name }}"
      class="floating-nav-item"
    >
      <span v-if="item.name == 'My Collection'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Runaway'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Home'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Settings'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>
      <span v-if="item.name == 'Cart'"
        ><img :src="getIcon(item)" alt="" width="25px" height="25px"
      /></span>

      <span
        v-if="item.name === 'Cart' && cartStore.cart?.length > 0"
        class="badge" style="width: 20px !important; height: 20px !important;"
        >
        <span style="opacity: 0">.</span></span
      >
    </router-link>
  </div>
</template>
<script setup>
import { useCartStore } from "@/stores/cartStore";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import glbselectedIcon from "@/assets/navbar/glbcli.svg";
import glbunselectedIcon from "@/assets/navbar/glbuncli.svg";
import bkselectedIcon from "@/assets/navbar/bkcli.svg";
import bkunselectedIcon from "@/assets/navbar/bkuncli.svg";
import hselectedIcon from "@/assets/navbar/hcli.svg";
import hunselectedIcon from "@/assets/navbar/huncli.svg";
import mbselectedIcon from "@/assets/navbar/mbcli.svg";
import mbunselectedIcon from "@/assets/navbar/mbuncli.svg";
import mwselectedIcon from "@/assets/navbar/mwcli.svg";
import mwunselectedIcon from "@/assets/navbar/mwuncli.svg";

const route = useRoute();
const selroute = ref("/");

watch((route) => {
  console.log(route);
});

const getIcon = (item) => {
  selroute.value = route.path == "/home" ? "/" : route.path;
  if (!item) return "";

  let sel = "";
  let unsel = "";
  switch (item.name) {
    case "Home":
      sel = hselectedIcon;
      unsel = hunselectedIcon;
      break;
    case "My Collection":
      sel = bkselectedIcon;
      unsel = bkunselectedIcon;
      break;
    case "Runaway":
      sel = glbselectedIcon;
      unsel = glbunselectedIcon;
      break;
    case "Cart":
      sel = mwselectedIcon;
      unsel = mwunselectedIcon;
      break;
    case "Settings":
      sel = mbselectedIcon;
      unsel = mbunselectedIcon;
      break;

    default:
      break;
  }
  return selroute.value === item.route ? sel : unsel;
};

const navItems = [
  { name: "Home", icon: "fas fa-home", route: "/" },
  { name: "My Collection", icon: "fas fa-bookmark", route: "/collection" },
  { name: "Runaway", icon: "fa-solid fa-globe", route: "/runaway" },
  { name: "Cart", icon: "fa-solid fa-cart-shopping", route: "/Cart" },
  { name: "Settings", icon: "fas fa-cog", route: "/settings" },
];
const cartStore = useCartStore();
</script>

<style scoped>
/* START Mobile Bottom Navbar */
.navbar-mobile {
  height: 7%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  background-color: #ffffff !important;
}

.nav-item {
  text-align: center;
  color: #000000;
  flex-grow: 1;
}

.nav-item i {
  font-size: 1.5rem;
  display: block;
}

.nav-item span {
  font-size: 1.1rem;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}
/* END Mobile Bottom Navbar */

/* START Floating Navbar for Web */

/* Floating Navbar for Web - Centered */
.floating-navbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 10px 20px;
  z-index: 1000;
  width: auto; /* Will adjust to content width */
}

.floating-nav-item {
  width: 70px;
  text-align: center;
  color: #555;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.floating-nav-item:hover {
  color: #007bff;
}

.floating-nav-item i {
  font-size: 1.75rem;
  display: block;
}

.floating-nav-item span {
  font-size: 1.1rem;
}
/* END Floating Navbar for Web */

.nav-item,
.floating-nav-item {
  position: relative;
}

.badge {
  position: absolute;
  top: -10px;
  background-color: red;
  color: white;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 50%;
  min-width: 18px;
  text-align: center;
  width: 15px;
  height: 18px;
}
</style>
