<template>
  <div class="top-bar">
    <!-- Left Logo -->
    <div class="logo">
      <img
        src="@/assets/wlogob.svg"
        alt="Delivery Items"
        width="40"
        height="40"
      />
    </div>

    <!-- Center Search Input with Search and Close Icon -->
    <div class="search-container" :class="{ 'active-search': searchApplied }">
      <button
        class="search-icon-btn search-icon"
        :class="{ 'active-btn-search': searchApplied }"
      >
        <i
          class="fas fa-search"
          :class="{ 'active-btn-search': searchApplied }"
        ></i>
      </button>
      <input
        type="text"
        v-model="localQuery"
        class="search-input"
        :placeholder="searchApplied ? '' : 'Search'"
        @keyup.enter="applySearch"
      />
      <button
        v-if="searchApplied"
        class="close-icon-btn close-icon"
        @click="clearSearch"
        :class="{ 'active-btn-search': searchApplied }"
      >
        <i
          class="fas fa-times"
          :class="{ 'active-btn-search': searchApplied }"
          style="padding-right: 0.4rem"
        ></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const localQuery = ref("");
const searchApplied = ref(false);
const emit = defineEmits(["update:modelValue"]);

const applySearch = () => {
  emit("update:modelValue", localQuery.value);
  searchApplied.value = true;
};

const clearSearch = () => {
  localQuery.value = "";
  searchApplied.value = false;
  emit("update:modelValue", "");
};
</script>

<style scoped>
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-right: 0px;
}

.logo {
  font-size: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 10px 0px 10px 10px;
  position: relative;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.search-input {
  width: 100%;
  padding: 8px 15px 8px 35px;
  border: none;
  font-size: 14px;
  outline: none;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.search-input:focus {
  outline: none;
}

.search-container.active-search {
  border: none;
  font-size: 14px;
  outline: none;
  background-color: black;
  color: white !important;
}

.search-container.active-search input {
  color: white !important;
}

.search-icon-btn {
  position: absolute;
  left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.search-icon {
  font-size: 18px;
  color: #666;
}

.close-icon-btn {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-icon {
  font-size: 18px;
  color: #666;
}
.active-btn-search {
  color: white !important;
}
</style>
