<template>
  <div class="review-section mx-2 pb-3">
    <div class="review-header">
      <h3>Reviews <span class="review-count">{{ reviewCount }}</span></h3>
      <div class="rating">
        <i class="fas fa-star star-icon" v-for="star in overallRating" :key="star"></i>
      </div>
    </div>

    <div v-if="reviewCount == undefined || reviewCount == 0" style="text-align: center;">
      <p>No Reviews Yet</p>
    </div>
    <div class="carousel" v-if="reviewCount != undefined && reviewCount >0">
      <div v-for="review in reviews" :key="review.id" class="review-item">
        <p class="review-text">{{ review.comment }}</p>
        <p class="review-date">{{ review.date }}</p>
        <p class="review-rating">Rating: {{ review.rating }} <span class="stars">{{ '★'.repeat(review.rating) }}</span>
        </p>
      </div>
    </div>

    <button @click="seeAllReviews" v-if="reviewCount != undefined && reviewCount >0" class="show-all-button">Show All Reviews</button>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  reviews: Array,
  reviewCount: Number,
  overallRating: Number,
});

const seeAllReviews = () => {
  console.log('Redirecting to all reviews');
};
</script>

<style scoped>
.review-section {
  margin: 1rem 0;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-count {
  color: gray;
  /* Different color for the review count */
  font-weight: bold;
  /* Make the count bold */
}

.rating {
  font-size: 1.5rem;
  /* Adjust the size of the star icons */
}

.star-icon {
  color: gold;
  /* Color for the stars */
}

.carousel {
  display: flex;
  overflow-x: scroll;
  gap: 1rem;
  /* Space between reviews */
  scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar in Chrome, Safari */
}

.review-item {
  min-width: 300px;
  /* Adjust as needed for your design */
  border: 1px solid black;
  padding: 0rem 1rem;
  border-radius: 8px;
  /* Rounded corners */
}

.review-text {
  margin: 0.5rem 0;
  /* Margin for review text */
}

.review-date {
  font-size: 0.9rem;
  /* Smaller font for date */
  color: gray;
  /* Color for date text */
}

.review-rating {
  font-size: 0.9rem;
  /* Smaller font for rating text */
}

.show-all-button {
  width: 100%;
  padding: 1rem;
  border: 1px solid black;
  background-color: transparent;
  /* Transparent background */
  color: black;
  cursor: pointer;
  border-radius: 50px;
  /* Rounded corners */
  margin-top: 1rem;
  /* Margin on top */
  transition: background-color 0.3s;
  /* Smooth transition */
}

.show-all-button:hover {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}
</style>



<!-- 
<template>
    <div class="review-section">
      <h3>Reviews</h3>
      <div class="carousel">
        <div v-for="review in reviews" :key="review.id" class="review-item">
          <p>Rating: {{ review.rating }}</p>
          <p>{{ review.comment }}</p>
        </div>
      </div>
      <button @click="seeAllReviews">See All Reviews</button>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  defineProps({
    reviews: Array,
  });
  
  const seeAllReviews = () => {
    console.log('Redirecting to all reviews');
  };
  </script>
  
  <style scoped>
  .review-section {
    margin: 2rem 0;
  }
  
  .carousel {
    display: flex;
    overflow-x: scroll;
  }
  
  .review-item {
    min-width: 100%;
    flex: 0 0 auto;
    border: 1px solid black;
    padding: 1rem;
  }
  </style>
   -->