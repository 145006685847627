import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useproductpageStore = defineStore('productpagestore', () => {
  // State
  const selectedproduct = ref(null);

  // Actions
  const selectproduct = (_product) => {
    // Add to state
    selectedproduct.value = JSON.parse(JSON.stringify(_product));
  };

  return {
    selectedproduct,
    selectproduct,
  };
});
