import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import router from './router' 
import App from './App.vue'
import { createPinia } from 'pinia';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Import Bootstrap and Bootstrap's CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import '@ionic/vue/css/core.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

const pinia = createPinia();

createApp(App).use(pinia).use(IonicVue).use(router).mount('#app')
