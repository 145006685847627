<template>
    <FullScreenLoader :isVisible="intialloading" />
    <div class="login-container mx-4">
      <div class="card p-4 login-card">
        <img
          src="@/assets/logo-bh.png"
          alt="Delivery Items"
          width="100"
          height="100"
          style="align-self: center"
        />
        <h2 class="text-center mb-4">Forgot Password</h2>
  
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter email"
            v-model="email"
          />
          <div class="mx-2 mb-2 mt-2" style="color: red" v-if="error.email != ''">
            <span>{{ error.email }}</span> <br />
          </div>
        </div>
        <button
          class="btn btn-dark w-100"
          @click="handleLogin"
          style="border-radius: 50px"
        >
          Reset Password
        </button>
        <button
          class="btn btn-dark w-100 mt-3"
          @click="handleRegister"
          style="border-radius: 50px; background-color: #737373 !important"
        >
          Go Back
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import { useUserStore } from "@/stores/userStore"; // Adjust the import path based on your directory structure
  import { useRouter } from "vue-router";
  import { URLService } from "@/services/_url";
  import { UserService } from "@/services/userservice";
  import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
  
  const userstore = useUserStore();
  const email = ref("");
  const error = ref({
    name: "",
    email: "",
    password: "",
  });
  
  const router = useRouter();
  const intialloading = ref(false);
  
  const handleLogin = async () => {
    try {
      if ((await validate()) == false) return;
      intialloading.value = true;
  
      var url = URLService.resetpassword;
      let response = await UserService.passwordreset(url, {
        email: email.value
      });
  
      if (response.status >= 200 && response.status < 400) {
        router.push({ name: "Login" });
      } else {
        error.value.email = "Email is not valid";
      }
    } catch (er) {
      intialloading.value = false;
      if (er.status > 0) {
        error.value.password = er.response.data.message;
      } else {
        er.message;
      }
    }
  };
  const handleRegister = () => {
    router.go(-1);
  };
  
  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  
  const validate = () => {
    let validated = true;
    error.value.email = "";
    error.value.password = "";
    if (validateEmail(email.value) == false) {
      error.value.email = "Please enter a valid email";
      validated = false;
    }
    if (email.value == "" || email.value == undefined || email.value == null) {
      error.value.email = "Please enter your email";
      validated = false;
    }
    return validated;
  };
  
  onMounted(() => {
    if (
      userstore.user?.email != null &&
      userstore.user?.email != "" &&
      userstore.user?.email != undefined
    )
      router.push({ name: "Home" });
  });
  </script>
  
  <style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Full height for mobile and web */
  }
  
  .login-card {
    width: 100%;
    max-width: 400px;
    /* Limit the width on larger screens */
    /* border-radius: 10px; */
    background-color: #fff;
    /* border: 1px solid #ddd; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .form-control {
    border: 1px solid #ddd;
    background-color: #f8f9fa;
  }
  
  .btn-dark {
    background-color: #333;
    border-color: #333;
  }
  
  .btn-dark:hover {
    background-color: #555;
    border-color: #555;
  }
  
  .text-danger {
    color: #dc3545;
  }
  </style>
  