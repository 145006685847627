<template>
  <div class="runaway-page">
    <header class="collection-header mb-3">
      <h2>{{ name }}</h2>
    </header>
    <div v-if="images.length == 0" class="text-center mt-5">
      No items added yet
    </div>
    <div
      class="grid-container"
      style="max-height: 85vh; overflow-y: auto; overflow-x: hidden"
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="
          index % 6 == 1 || index % 6 == 3 ? 'grid-item' : 'grid-item'
        "
        @click="gotoproduct(image)"
      >
        <img :src="image.image" :alt="image.vendor_name" class="image" />
        <div class="image-info">
          <small>From {{ image.vendor_name }}</small>
        </div>
      </div>
    </div>
  </div>
  <RunAway
    :data="selectedrunaway"
    :showmodal="showmodal"
    @update:showmodal="showmodal = !showmodal"
  />

  <ion-toast
    id="toast"
    trigger="open-toast"
    :is-open="isOpen"
    message="You don't have access to see the collection"
    :duration="3000"
  ></ion-toast>
</template>

<script setup>
import RunAway from "@/components/RunAway.vue";
import { IonToast } from "@ionic/vue";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const images = ref([]);

const selectedrunaway = ref(null);
const showmodal = ref(false);
const isOpen = ref(false);
const name = ref("");

const gotoproduct = (image) => {
  router.push({ name: "ProductPage", params: { id: image.id } });
};

onMounted(async () => {
  let id = route.params.id;

  var localCollection = localStorage.getItem("userCollection");
  if (localCollection && localCollection != "") {
    let collections = JSON.parse(localCollection);
    let selCollection = collections.filter((x) => {
      return x.id == id;
    }); 
    if (selCollection.length > 0) {
      try {
        if (selCollection[0].data == "" || selCollection[0].data == null) {
          images.value = [];
          name.value = selCollection[0].name;
          return;
        }
        let data = JSON.parse(selCollection[0].data);
        name.value = selCollection[0].name;
        images.value = data;
      } catch (error) {
        isOpen.value = true;
        setTimeout(() => {
          router.go(-1);
        }, 4000);
      }
    } else {
      router.go(-1);
    }
  }
});
</script>

<style scoped>
.runaway-page {
  padding: 1rem;
}

.collection-header {
  display: flex;
  justify-content: center;
  margin: 0px 26px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-container::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.grid-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  /* aspect-ratio: 3 / 4;  */
  /* Standard image */
}

.grid-item-large {
  grid-column: span 2;
  grid-row: span 2;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 2 / 3; /* Large image in portrait format */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 76%
  );
  color: white;
  text-align: left;
  border-radius: 0 0 15px 15px;
}
</style>
