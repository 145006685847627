<template>
  <div class="vendor-info mx-2">
    <!-- Vendor logo and name section -->
    <div class="vendor-logo-name" @click="goToVendorPage">
      <div class="logo-container">
        <img v-if="vendor?.logo_url" :src="vendor?.logo_url" alt="Vendor Logo" class="vendor-logo" />
        <span v-if="vendor?.logo_url == undefined" style="
    font-size: 1.2rem;
    text-align: center;padding: 12px;" class="vendor-logo">{{ vendor?.name[0].toUpperCase() }}</span>
      </div>
      <span class="vendor-name">{{ vendor?.name }}</span>
    </div>

    <!-- Add to collection button -->
    <button class="add-to-collection-btn" @click="$emit('add-to-collection')">
      <img :src="bkunselectedIcon" width="30px" height="30px"/>
    </button>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';


// import bkselectedIcon from "@/assets/navbar/bkcli.svg";
import bkunselectedIcon from "@/assets/navbar/bkuncli.svg";

const props = defineProps({
  vendor: Object,
});

// Setup Vue Router
const router = useRouter();

// Navigate to product page
const goToVendorPage = () => {
  router.push({ name: 'VendorPage', params: { id: props.vendor.id } });
};
</script>

<style scoped>
.vendor-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  /* Light background for vendor info */
  border-radius: 15px;
  /* Rounded tablet design */
}

.vendor-logo-name {
  border: black solid 1px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  display: flex;
  align-items: center;
  background-color: white;
  /* Background for logo and name section */
  padding: 10px;
  border-radius: 50px;
  /* Rounded tablet look */
}

.logo-container {
  background-color: black;
  /* Black background for the logo */
  border-radius: 50%;
  /* Make the logo container round */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  /* Adjusted width for the logo container */
  height: 60px;
  /* Adjusted height for the logo container */
}

.vendor-logo {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: contain;
  color: white;
  /* Ensures the logo fits within the circle */
}

.vendor-name {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.add-to-collection-btn {
  background-color: white;
  border: 1px solid black !important;
  color: white;
  border: none;
  width: 60px;
  height: 60px;
  padding-top: 2px !important;
  border-radius: 30px;
  /* Rounded button design */
  font-size: 14px;
  cursor: pointer;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 576px) {
  .vendor-logo-name {
    padding-left: 3px !important;
    padding: 3px;
  }

  .vendor-logo {
    width: 50px;
    height: 50px;
  }

  .logo-container {
    width: 50px;
    height: 50px;
  }

  .add-to-collection-btn {
    padding: 6px 12px;
    font-size: 30px;
  }
}
</style>




<!--!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->

<!-- VendorInfo.vue
<template>
    <div class="vendor-info">
      <img :src="vendor.logo" alt="Vendor Logo" class="vendor-logo" />
      <span>{{ vendor.name }}</span>
      <button @click="$emit('add-to-collection')">Add to Collection</button>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  defineProps({
    vendor: Object,
  });
  </script>
  
  <style scoped>
  .vendor-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .vendor-logo {
    width: 50px;
    height: 50px;
  }
  </style>
   -->