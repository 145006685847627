import CryptoJS from 'crypto-js'
const keyValue = 'Hello_wardrobe' // your key value (eg: key)
const ivKey = 'a2xhcgAAADFEAAAA' // your iv key value (eg: a2xhcgAAAAAAAAAA)

export function encrypteData(data) {
  if (data) {
    const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 })
    const iv = CryptoJS.enc.Utf8.parse(ivKey) // Convert string to WordArray
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv, mode: CryptoJS.mode.CBC })
    return encrypted.toString()
  } else {
    return ''
  }
}

export function decrypteData(data) {
  if (data) {
    const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 })
    const iv = CryptoJS.enc.Utf8.parse(ivKey)
    const decrypted = CryptoJS.AES.decrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }
  return '{}'
}
