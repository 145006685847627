export const URLService = {
    getProducts : process.env.VUE_APP_BASE_URL + "api/mobile/",
    getcommondata : process.env.VUE_APP_BASE_URL + "api/mobile/",
    getproduct : process.env.VUE_APP_BASE_URL + "api/mobile/product/:id",
    checkpromo : process.env.VUE_APP_BASE_URL + "api/mobile/check-promo-code",
    getvendorpolicies : process.env.VUE_APP_BASE_URL + "api/mobile/getpolicies",
    getvendor : process.env.VUE_APP_BASE_URL + "api/mobile/vendor/:id",
    baseurl: process.env.VUE_APP_BASE_URL,
    register: process.env.VUE_APP_BASE_URL+ "api/mobile/register",
    login: process.env.VUE_APP_BASE_URL+ "api/mobile/login",
    resetpassword: process.env.VUE_APP_BASE_URL+ "api/mobile/resetpassword",
    uploadprofile: process.env.VUE_APP_BASE_URL+ "api/mobile/me/uploadprofileimage",
    getme: process.env.VUE_APP_BASE_URL+ "api/mobile/me",
    updateprofile: process.env.VUE_APP_BASE_URL+ "api/mobile/me/update",
    createbooking: process.env.VUE_APP_BASE_URL+ "api/mobile/bookings",
    getbookings: process.env.VUE_APP_BASE_URL+ "api/mobile/bookings/{userid}",
    getreturnbookings: process.env.VUE_APP_BASE_URL+ "api/mobile/rebookings/{userid}",
    getrunaway: process.env.VUE_APP_BASE_URL+ "api/mobile/getrunaway",
    getcollections: process.env.VUE_APP_BASE_URL+ "api/mobile/getcollections/{userid}",
    addcollection: process.env.VUE_APP_BASE_URL+ "api/mobile/addcollection/{userid}",
    updatecollection: process.env.VUE_APP_BASE_URL+ "api/mobile/updatecollection/{userid}/{colid}",
    uploadreturnimage: process.env.VUE_APP_BASE_URL+ "api/mobile/uploadreturnimage",
    addReturn: process.env.VUE_APP_BASE_URL+ "api/mobile/addreturn",
    suggestbrand: process.env.VUE_APP_BASE_URL+ "api/mobile/suggestbrand",
  }
  