<template>
  <div class="top-buttons">
    <button class="back-btn" v-if="buttons.includes('backbtn')" @click="goBack">
      <i class="fa-solid fa-chevron-left" style="font-size: large;"></i>
    </button>
    <div class="header-title" v-if="buttons.includes('title')">
      {{ options?.title ?? "" }}
    </div>
    <div class="header-title" v-if="buttons.includes('image')">
      <img
        style="width: 200px !important"
        v-if="true != true"
        :src="options?.image"
        alt="Vendor Logo"
        class="vendor-logo"
      />
      <span v-else>{{ options?.name }}</span>
      
    </div>
    <div>
        <button style="opacity: 0;;" v-if="!buttons.includes('sharebtn')"></button>
      <button
        class="share-btn"
        v-if="buttons.includes('sharebtn')"
        @click="shareImage"
      >
        <i class="fas fa-share-alt"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
defineProps({
  buttons: Array,
  options: Object,
});

// Go back (placeholder function for navigation)
const goBack = () => {
  router.go(-1);
};

// Share image (placeholder function for sharing)
const shareImage = () => {
  // Add logic for sharing the current image (e.g., using Web Share API or custom action)
  alert("Share button clicked");
};
</script>

<style scoped>
.header-title {
  font-size: 1.7rem;
  font-weight: 800;
}

/* Top Buttons (Back and Share) */
.top-buttons {
  position: relative;
  height: 50px;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 2;
}

.back-btn,
.share-btn {
  border: 0px;
  background-color: white;
  color: black;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

/* Top Buttons (Back and Share) */
.back-btn,
.share-btn {
  background-color: white;
  color: black;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  /* Make the button round */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

/* Adjust icon size */
.back-btn i,
.share-btn i {
  font-size: 18px;
}

/* Responsive for smaller screens */
@media (max-width: 576px) {
  .carousel-image {
    aspect-ratio: 3 / 4;
  }

  .carousel-btn {
    padding: 8px;
    font-size: 20px;
  }

  .back-btn,
  .share-btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
</style>
