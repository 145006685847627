<template>
  <div class="add-to-cart-btn mx-3">
    <button @click="handleAddToCart" class="add-to-wardrobe-button">
      Add to Cart
    </button>
  </div>

  <div
    v-if="showTshirtIcon == true"
    :class="['tshirt-icon', { 'animate-icon': isAnimating }]"
    ref="tshirtIcon"
  >
    👕
  </div>

  <ion-toast
    id="toast"
    trigger="open-toast"
    :is-open="isOpen"
    :message="toastmessage"
    :duration="3000"
  ></ion-toast>
</template>

<script setup>
import { useCartStore } from "@/stores/cartStore";
import { defineProps, ref } from "vue";
import { IonToast } from "@ionic/vue";

const cartStore = useCartStore();
const showTshirtIcon = ref(false);
const isAnimating = ref(false);
const tshirtIcon = ref(null);

const props = defineProps({
  product: Object,
  selectedLocation: Object,
  selectedColor: String,
  selectedSize: String,
  selectedQuantity: Number,
});

const toastmessage = ref("");
const isOpen = ref(false);

const handleAddToCart = async () => {
  const productDetails = {
    ...props.product,
    location: JSON.parse(JSON.stringify(props.selectedLocation)),
    color: JSON.parse(JSON.stringify(props.selectedColor)),
    size: JSON.parse(JSON.stringify(props.selectedSize)),
    quantity: JSON.parse(JSON.stringify(props.selectedQuantity)),
  };

  if (!props.selectedColor || !props.selectedSize) {
    if (!props.selectedColor)
      toastmessage.value = "Please select a color variant";
    else if (!props.selectedSize)
      toastmessage.value = "Please select a size you prefer";

    isOpen.value = true;
    setTimeout(() => {
      isOpen.value = false;
    }, 1000);
    await cartStore.setissubmittedtrue();
    return;
  }
  // Add the product to the cart store
  await cartStore.addProductToCart(productDetails);
};

// const animation = () => {
//   isAnimating.value = true;

//   var a = document.getElementById("Cart");
//   const rect = a.getBoundingClientRect();
//   const x = rect.left + window.scrollX;
//   const y = rect.bottom;

//   // Apply the animation by adding a style directly
//   tshirtIcon.value.style.transition = "transform 1s ease";
//   tshirtIcon.value.style.transform = "translateX("+x+"px, "+y+"px)";

//   // Reset the animation state after it's completed (2 seconds)
//   setTimeout(() => {
//     isAnimating.value = false;
//     tshirtIcon.value.style.transform = ""; // Reset the transform
//   }, 2000);

// };
</script>

<style scoped>
.add-to-cart-btn {
}

/* .add-to-wardrobe-button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
} */

.add-to-wardrobe-button {
  width: 100%;
  padding: 1rem;
  background-color: black;
  color: white;
  border: 1px solid black;
  /* Optional: Add border to match other elements */
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 50px;
  /* Increase for more rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transition effects */
}

/* Hover effect */
.add-to-wardrobe-button:hover {
}

.product-page {
  text-align: center;
  padding: 20px;
}

.add-to-wardrobe-btn {
  background-color: black;
  color: white;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.shipping-policy-btn {
  background-color: lightgray;
  color: black;
  border-radius: 25px;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
}

.reviews-section {
  margin-top: 20px;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.nav-icon {
  font-size: 24px;
}

.tshirt-icon {
  position: absolute;
  font-size: 24px;
  transition: all 1s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

.animate-icon {
  /* End position - matches the target in the bottom nav */
  transform: translate(calc(50vw - 100px), calc(100vh - 80px));
  opacity: 1;
}
</style>
