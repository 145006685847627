<!-- src/components/FilterModal.vue -->
<template>
  <div class="modal" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <h2 class="mb-4">Add your location</h2>

      <div
        v-for="address in addressList"
        v-bind:key="address.address1"
        class="address mb-2 d-flex justify-content-between align-items-center p-2"
        style="padding: 10px 0"
        @click="selectAddress(address)"
      >
        <div>
          <span style="font-weight: 800">{{ address.name }}</span> <br />
          <span>
            {{ address.address1 }}, {{ address.address2 }},
            {{ address.city }} ({{ address.postalcode }})
          </span>
        </div>
        <div>
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
      <!-- <div
        v-for="address in AdressList"
        v-bind:key="address.address1"
        class="address mb-2"
      >
        <div class="w-75 float-left">
          <span style="font-weight: 800">{{ address.name }}</span> <br />
          <span
            >{{ address.address1 }}, {{ address.address2 }} <br />
            {{ address.city }}.({{ address.postalcode }} )</span
          >
        </div>
        <div class="w-25 float-left"><i class="fa-solid fa-chevron-right"></i></div>
      </div> -->
      <div class="mb-3 mt-3" v-if="userStore.isLoggedIn()">
        <label for="fromName" class="form-label">Name</label>
        <input
          type="text"
          id="fromName"
          class="form-control"
          v-model="address.name"
          maxlength="30"
          required
        />
      </div>

      <div class="mb-3">
        <label for="fromName" class="form-label">Address Line 1</label>
        <input
          type="text"
          id="fromName"
          class="form-control"
          v-model="address.address1"
          maxlength="30"
          required
        />
      </div>

      <div class="mb-3">
        <label for="toName" class="form-label">Address Line 2</label>
        <input
          type="text"
          id="toName"
          class="form-control"
          v-model="address.address2"
          maxlength="30"
          required
        />
      </div>

      <div class="mb-3">
        <label for="phone" class="form-label">City</label>
        <input
          type="text"
          v-model="searchTerm"
          @input="filterCities"
          @focus="showDropdown = true"
          @blur="closeDropdown"
          placeholder="Select a city"
          class="form-control"
        />
        <ul v-if="showDropdown && filteredCities.length" class="dropdown-list mx-3">
          <li
            v-for="(city, index) in filteredCities"
            :key="index"
            @mousedown="onCityChange(city.city)"
            class="dropdown-item"
          >
            {{ city.city }}
          </li>
        </ul>
        <!-- <select
          class="form-select"
          aria-label="Default select example"
          v-model="address.city"
          @change="onCityChange($event)"
        >
          <option value="0">Select a city</option>
          <option
            v-for="city in cities"
            :value="city.city"
            v-bind:key="city.city"
          >
            {{ city.city }}
          </option>
        </select> -->
      </div>

      <div class="mb-3" style="display: none">
        <label for="province" class="form-label">Province</label>
        <input
          type="text"
          class="form-control"
          id="province"
          disabled
          v-model="address.province"
        />
      </div>

      <div class="mb-3">
        <label for="postalcode" class="form-label">Postalcode</label>
        <input
          type="text"
          class="form-control"
          id="postalcode"
          v-model="address.postalcode"
        />
      </div>

      <div class="modal-actions" v-if="!showSelection">
        <button @click="cancelFilters">Cancel</button>
        <button
          v-if="userStore.isLoggedIn()"
          @click="applyFilters"
          style="background-color: black !important; color: white"
        >
          Save
        </button>
        <button
          v-if="!userStore.isLoggedIn()"
          @click="applyFilters"
          style="background-color: black !important; color: white"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/userStore";
import { ref, defineProps, defineEmits } from "vue";

const userStore = useUserStore();

const filterCities = () => {
  if (searchTerm.value) {
    filteredCities.value = props.cities.filter((city) =>
      city.city.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  } else {
    filteredCities.value = [];
  }
};
const $emit = defineEmits(["saveaddress"]);
const saveaddress = () => {
  var selCity = props.cities.filter((city) => {
    return city.city == address.value.city;
  });
  let district = selCity.length > 0 ? selCity[0].district : "";
  let _address = {
    name: address.value.name,
    address1: address.value.address1,
    address2: address.value.address2,
    address3: address.value.address3,
    city: address.value.city,
    province: address.value.province,
    postalcode: address.value.postalcode,
    district: district,
  };
  $emit("saveaddress", _address);
  address.value = {
    name: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    province: "",
    district: "",
    postalcode: "",
  };
};

const selectAddress = (_address) => {
  var selCity = props.cities.filter((city) => {
    return city.city == _address.city;
  });
  let district = selCity.length > 0 ? selCity[0].district : "";
  _address.district = district;
  $emit("saveaddress", _address);
};

// const AdressList = ref([
//   {
//     name: "Home",
//     address1: "98/A",
//     address2: "Ragama",
//     address3: "",
//     city: "Gampaha",
//     province: "Western",
//     postalcode: "52007",
//   },
//   {
//     name: "Office",
//     address1: "102V",
//     address2: "Asgiriya",
//     address3: "Gampaha",
//     city: "Gampaha",
//     province: "Western",
//     postalcode: "11050",
//   },
// ]);

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
  cities: {
    type: Array,
  },
  addressList: {
    type: Array,
  },
});

const searchTerm = ref("");
const filteredCities = ref([]);
const showDropdown = ref(false);

const onCityChange = (event) => {
  showDropdown.value = false;
  var selCity = props.cities.filter((city) => {
    return city.city == event;
  });
  searchTerm.value = event;
  address.value.province = selCity.length > 0 ? selCity[0].province : "";
  address.value.district = selCity.length > 0 ? selCity[0].district : "";
};

const closeDropdown = () => {
  setTimeout(() => (showDropdown.value = false), 100); // Delay to allow click selection
};

const showSelection = ref(false);
const address = ref({
  name: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  province: "",
  district: "",
  postalcode: "",
});

const applyFilters = async () => {
  await saveaddress();
  props.closeModal();
};

const cancelFilters = () => {
  props.closeModal();
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

.filter-options {
  list-style: none;
  padding: 0;
}

.filter-options li {
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-options li:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  background-color: white !important;
}

.btn-apply {
  background-color: white;
  color: black;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}

.filter-options li.active {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}

.address {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  background-color: #eaeaea;
}

.autocomplete-container {
  position: relative;
}

.dropdown-list {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  z-index: 1000;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>
