import axios from 'axios'

// const baseurl = import.meta.env.VITE_APP_BaseURL
// axios.defaults.baseURL = baseurl

export const ProductService = {
  // init({ message: message == "" ? "Error Occured, Please contact administrator" : message, color: 'success' })
  async getProductList(url, request) {
    return await axios.post(url, request)
  },
  async getProduct(url) {
    return await axios.get(url)
  },
  async uploadReturnImage(url, data) {
    return await axios.post(url, data)
  },
  async createreturn(url, data) {
    return await axios.post(url, data)
  }
}
