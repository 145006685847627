<template>
    <div class="container mt-5">
      <h1>Product List</h1>
      <p>Here you can find all our products.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProductList',
  }
  </script>