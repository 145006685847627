<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="login-container mx-4">
    <div class="card p-4 login-card">
      <img
        src="@/assets/logo-bh.png"
        alt="Delivery Items"
        width="100"
        height="100"
        style="align-self: center"
      />

      <div v-if="isregstered == true">
        Your wardrobe is preparing, please check your email to activate your
        account.
      </div>
      <div v-if="isregstered == false">
        <h2 class="text-center mb-4">Register</h2>
        <div class="mb-2">
          <label for="fname" class="form-label"
            >Name <span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="fname"
            placeholder="Enter name"
            v-model="name"
          />
          <div
            class="mx-2 mb-2 mt-2"
            style="color: red"
            v-if="error.name != ''"
          >
            <span>{{ error.name }}</span> <br />
          </div>
        </div>

        <div class="mb-2">
          <label for="email" class="form-label"
            >Email address <span style="color: red">*</span></label
          >
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter email"
            v-model="email"
          />
          <div class="mx-2" style="color: red" v-if="error.email != ''">
            <span>{{ error.email }}</span> <br />
          </div>
        </div>
        <div class="mb-2">
          <label for="password" class="form-label"
            >Password <span style="color: red">*</span></label
          >
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            v-model="password"
          />
        </div>
        <div class="mb-2">
          <label for="rpassword" class="form-label"
            >Repeat Password <span style="color: red">*</span></label
          >
          <input
            type="password"
            class="form-control"
            id="rpassword"
            placeholder="Repeat Password"
            v-model="rpassword"
          />
          <div
            class="mx-2 mb-2 mt-2"
            style="color: red"
            v-if="error.password != ''"
          >
            <span>{{ error.password }}</span> <br />
          </div>
        </div>
      </div>
      <button
        class="btn btn-dark mt-3 w-100"
        @click="handleRegister"
        style="border-radius: 50px"
        v-if="isregstered == false"
      >
        Register
      </button>
      <button
        class="btn btn-dark w-100 mt-3"
        @click="handleLogin"
        style="border-radius: 50px; background-color: #737373 !important"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue"; // Adjust the import path based on your directory structure
import { useRouter } from "vue-router";
import { UserService } from "@/services/userservice";
import { URLService } from "@/services/_url";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import { useUserStore } from "@/stores/userStore";

const userstore = useUserStore()
const email = ref("");
const password = ref("");
const name = ref("");
const rpassword = ref("");
const error = ref({
  name: "",
  email: "",
  password: "",
});
const isregstered = ref(false);
const intialloading = ref(false);

const router = useRouter();

const handleLogin = () => {
  router.push({ name: "Login" });
};

watch(email, () => {
  validate();
});
watch(name, () => {
  validate();
});
watch(password, () => {
  validate();
});
watch(rpassword, () => {
  validate();
});

const handleRegister = async () => {
  try {
    if ((await validate()) == false) return;
    intialloading.value = true;

    var url = URLService.register;
    let response = await UserService.registeruser(url, {
      name: name.value,
      email: email.value,
      password: password.value,
    });
    isregstered.value = true;
    console.log(response);
    intialloading.value = false;
    // userStore.setLogin(response.data);

    // router.push("/login");
  } catch (er) {
    if (er.status > 0) {
      error.value.password = er.response.data.message;
    } else {
      er.message;
    }
    isregstered.value = false;
    intialloading.value = false;
  }
};

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

const validate = () => {
  let validated = true;
  error.value.name = "";
  error.value.email = "";
  error.value.password = "";
  if (name.value == "" || name.value == undefined || name.value == null) {
    error.value.name = "Please enter your name";
    validated = false;
  }
  if (validateEmail(email.value) == false) {
    error.value.email = "Please enter a valid email";
    validated = false;
  }
  if (email.value == "" || email.value == undefined || email.value == null) {
    error.value.email = "Please enter your email";
    validated = false;
  }
  if (
    password.value == "" ||
    password.value == undefined ||
    password.value == null
  ) {
    error.value.password = "Please enter your password";
    validated = false;
  }
  if (
    rpassword.value == "" ||
    rpassword.value == undefined ||
    rpassword.value == null
  ) {
    error.value.password = "Please re-enter your password";
    validated = false;
  }
  if (rpassword.value != password.value) {
    error.value.password = "Password do noth match";
    validated = false;
  }
  return validated;
};


onMounted(() => {
  if(userstore.user.email != null && userstore.user.email != ''&& userstore.user.email != undefined)
        router.push({name: 'Home'});
})
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* Full height for mobile and web */
}

.login-card {
  width: 100%;
  max-width: 400px;
  /* Limit the width on larger screens */
  /* border-radius: 10px; */
  background-color: #fff;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.form-control {
  border: 1px solid #ddd;
  background-color: #f8f9fa;
}

.btn-dark {
  background-color: #333;
  border-color: #333;
}

.btn-dark:hover {
  background-color: #555;
  border-color: #555;
}

.text-danger {
  color: #dc3545;
}
</style>
