<template>
  <div class="app">
    <div class="safe-area">
      <!-- Main Content -->
      <router-view />
    </div>
    <NavBar />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import NavBar from "./components/NavBar";
import { useCartStore } from "@/stores/cartStore";
import "intl-tel-input/build/css/intlTelInput.css";
import { useUserStore } from "./stores/userStore";
import { CommonService } from "./services/commonservice";
import { useVendorStore } from "./stores/vendorStore";

const cartStore = useCartStore();
const userStore = useUserStore();
const vendorStore = useVendorStore();
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

onMounted(async () => {
  if (Capacitor.isNativePlatform()) {
    await Browser.clearCookies();
    await cartStore.fetchOrder();
    userStore.loadUserFromStorage();
    try {
      CommonService.getVendorPolicies().then((data) => {
        vendorStore.setpolicies(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  } else {
    await cartStore.fetchOrder();
    userStore.loadUserFromStorage();
    try {
      CommonService.getVendorPolicies().then((data) => {
        vendorStore.setpolicies(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }
});
</script>

<style scoped>
.iti {
  --iti-path-flags-1x: url("../node_modules/intl-tel-input/build/img/flags.webp");
  --iti-path-flags-2x: url("../node_modules/intl-tel-input/build/img/flags@2x.webp");
  --iti-path-globe-1x: url("../node_modules/intl-tel-input/build/img/globe.webp");
  --iti-path-globe-2x: url("../node_modules/intl-tel-input/build/img/globe@2x.webp");
}

.safe-area {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
