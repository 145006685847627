<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="container" style="height: 100%">
    <SearchBar v-model="searchQuery" />
    <FilterOptions />
    <!-- <ImageCarousel :products="_prods" /> -->
    <ProductGrid
      class="mt-3 mb-2"
      :products="filteredProducts"
      :loading="loading"
      :showslider="true"
      @load-more="loadMoreProducts"
      @add-to-collection="onAddToCollection"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import FilterOptions from "@/components/FilterOptions.vue";
import ProductGrid from "@/components/ProductGrid.vue";
import { ProductService } from "@/services/productservice";
import { useFilterStore } from "@/stores/filterstore.js";
import { URLService } from "@/services/_url";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import debounce from "lodash/debounce";
// import ImageCarousel from "@/components/home/ImageCarousel.vue";

const page = ref(1);
const filterStore = useFilterStore();

// const _prods = ref([
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
// ])

const searchQuery = ref("");
const products = ref([
  // { id: 1, name: 'Summer Dress', price: '$29.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 2, name: 'Casual Shirt', price: '$39.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 3, name: 'Jeans', price: '$49.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 4, name: 'Sneakers', price: '$59.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 5, name: 'Leather Jacket', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 6, name: 'Sunglasses', price: '$19.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 7, name: 'Sneakers', price: '$59.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 8, name: 'Leather Jacket', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 9, name: 'Sunglasses', price: '$19.99', image: 'https://via.placeholder.com/300x400' },
]);

const loading = ref(true); // Loading state
const intialloading = ref(true);
const loadMoreProducts = async () => {
  if (loading.value) return; // Prevent loading if already loading
  loading.value = true; // Set loading state

  page.value = page.value + 1;
  filterStore.selectedFilters.page = page.value;
  let filters = JSON.parse(JSON.stringify(filterStore.selectedFilters));
  filters.searchterm = searchQuery.value;
  let data = await ProductService.getProductList(
    URLService.getProducts,
    filters
  );

  if (data.status == 200) products.value.push(...data.data);
  else products.value.push(...[]);

  if (data.data.length <= 0) {
    loading.value = false;
  }

  // // Simulate an API call
  // setTimeout(() => {
  //   // Here you would typically fetch more products from your API
  //   const newProducts = [
  //     { id: 7, name: 'Hat', price: '$14.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 8, name: 'Belt', price: '$24.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 9, name: 'Watch', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 10, name: 'Bag', price: '$39.99', image: 'https://via.placeholder.com/300x400' },
  //   ];

  //   products.value.push(...newProducts);
  //   loading.value = false; // Reset loading state
  //   filteredProducts.value = products.value; // Update filtered products
  // }, 1500); // Simulating network delay
};

onMounted(() => {
  page.value = 0;
  reloadproducts();
});

const reloadproducts = async () => {
  products.value = [];
  page.value = 0;
  filterStore.selectedFilters.page = page.value;
  let filters = JSON.parse(JSON.stringify(filterStore.selectedFilters));
  filters.searchterm = searchQuery.value;
  let data = await ProductService.getProductList(
    URLService.getProducts,
    filters
  );
  if (data.status == 200) products.value = data.data;
  else products.value.push(...[]);

  intialloading.value = false;

  filteredProducts.value = products.value;
  loading.value = false;
  filterStore.reGetata = false;
};

const filteredProducts = computed(() => {
  return products.value;
  // if (!searchQuery.value) return products.value;
  // return products.value.filter((product) =>
  //   product.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  // );
});

const onAddToCollection = (product) => {
  console.log("Product added to collection:", product);
};

const triggerSearch = debounce(() => {
  if(searchQuery.value.length == 0){
    intialloading.value = true;
    reloadproducts();
  }
  if (searchQuery.value.length >= 3) {
    intialloading.value = true;
    reloadproducts();
  }
}, 100);

watch(searchQuery, (newQuery) => {
  triggerSearch(newQuery);
});

watch(
  () => filterStore.reGetata,
  (newValue) => {
    if (newValue == true) {
      reloadproducts();
    }
  }
);
</script>
