<template>
  <div class="cart-summary">
    <table style="width: 100%">
      <tbody>
        <tr>
          <td>Subtotal:</td>
          <td class="price-col">{{ subtotal }}</td>
          <td rowspan="3" style="text-align: right">
            <button
              class="ms-auto btn btn-danger border-round"
              @click="clearCart"
            >
              Clear Cart
            </button>
          </td>
        </tr>
        <!-- <tr>
          <td>Total:</td>
          <td class="price-col">{{ total }}</td>
          <td rowspan="3" style="text-align: right">
            <button
              class="ms-auto btn btn-danger border-round"
              @click="clearCart"
            >
              Clear Cart
            </button>
          </td>
        </tr>
        <tr>
          <td>Discount:</td>
          <td class="price-col">{{ discount }}</td>
        </tr>
        <tr>
          <td>Subtotal:</td>
          <td class="price-col">{{ subtotal }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>

  <div v-if="showModal" class="custom-modal-overlay" @click.self="closeModal">
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>
        <button class="close-btn" @click="closeModal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Do you want to clear the cart?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal">
          Cancel
        </button>
        <button class="btn btn-danger border-round" @click="confirmClearCart">
          Yes, Clear Cart
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from "@/stores/cartStore";
import { defineProps, onMounted, ref } from "vue";
const cartstore = useCartStore();

defineProps({
  subtotal: String,
  total: String,
  discount: String,
});

const clearCart = () => {
  showModal.value = true;
};
onMounted(() => {});

const showModal = ref(false);

function closeModal() {
  showModal.value = false;
}

function confirmClearCart() {
  cartstore.clearcart();
  window.location.reload();
}
</script>

<style scoped>
.cart-summary {
  position: sticky;
  bottom: -2px;
  background-color: white;
  padding: 0px 10px;
}

.btn-dark {
  background-color: #000;
}

.btn-dark:hover {
  background-color: #333;
}

.price-col {
  text-align: right;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.border-round {
  border-radius: 50px;
}
</style>
