<template>
  <div class="border p-3 rounded">
    <h5>Order Summary</h5>
    <div class="d-flex justify-content-between my-2">
      <span>Subtotal</span>
      <span> {{ subtotal }}</span>
    </div>
    <div class="d-flex justify-content-between my-2">
      <span>Discount</span>
      <span>{{ discount }}</span>
    </div>
    <div class="d-flex justify-content-between my-2">
      <span>Delivery Fee</span>
      <span>{{ deliveryFee }}</span>
    </div>
    <div
      class="d-flex justify-content-between my-2"
      v-if="order.promoAmount > 0 && order.promoAmount != undefined"
    >
      <span>Promo Discount</span>
      <span>{{ promototal }}</span>
    </div>
    <div class="d-flex justify-content-between my-2">
      <span>Taxes & Other Fees</span>
      <span>{{ taxes }}</span>
    </div>
    <hr />
    <div class="d-flex justify-content-between fw-bold">
      <span>Total</span>
      <span>{{ total }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default: () => ({
      subtotal: 0,
      deliveryFee: 0,
      taxes: 0,
      total: 0,
      discount: 0,
      promoAmount: 0,
    }),
  },
});

const promototal = computed(() => {
  return priceFormat.format(props.order.promoAmount * -1);
});
const deliveryFee = computed(() => {
  return priceFormat.format(props.order.deliveryFee);
});
const subtotal = computed(() => {
  return priceFormat.format(props.order.subtotal);
});
const discount = computed(() => {
  return priceFormat.format(props.order.discount);
});
const taxes = computed(() => {
  return priceFormat.format(props.order.taxes);
});
const total = computed(() => {
  return priceFormat.format(props.order.total);
});
</script>

<style scoped>
/* Add styling as needed */
</style>
