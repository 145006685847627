import axios from 'axios'
// const baseurl = import.meta.env.VITE_APP_BaseURL
// axios.defaults.baseURL = baseurl

export const UserService = {
  async registeruser(url, data) {
    return await axios.post(url, data)
  },
  async createcollection(url, data) {
    return await axios.post(url, data)
  },
  async addedtocollection(url, data) {
    return await axios.post(url, data)
  },
  async getcollections(url) {
    return await axios.get(url)
  },
  async loginuser(url, data) {
    return await axios.post(url, data)
  },
  async getuser(url) {
    return await axios.get(url)
  },
  async updateuser(url, data) {
    return await axios.post(url, data)
  },
  async uploadImage(url, formData) {
    // '/api/mobile/me/uploadprofileimage'
    return await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  async passwordreset(url, data) {
    return await axios.post(url, data)
  },
}
