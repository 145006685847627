<template>
  <TopButtons :buttons="['backbtn', 'sharebtn']" />
  <div class="carousel-wrapper mb-4">
    <div
      class="slider-container"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    >
      <!-- Images with Peek Effect -->
      <div
        class="slider"
        :style="{
          transform: `translateX(calc(-${
            currentIndex * 100
          }% + ${swipeOffset}px))`,
        }"
      >
        <div v-if="images.length == 0" class="slide">
          <img
            src="@/assets/300x400.svg"
            alt="Slide Image"
            class="d-block w-100 border rounded carousel-image"
            style="object-fit: cover"
          />
        </div>
        <div v-for="(image, index) in images" :key="index" class="slide">
          <img
            :src="image"
            alt="Slide Image"
            class="d-block w-100 border rounded carousel-image"
            style="object-fit: cover"
          />
        </div>
      </div>

      <!-- Navigation Dots -->
      <div class="dots">
        <span
          v-for="(image, index) in images"
          :key="'dot-' + index"
          :class="{ dot: true, active: currentIndex === index }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps } from "vue";
import TopButtons from "../common/TopButtons.vue";

const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
  altText: {
    type: String,
    default: "Product Image",
  },
});

const currentIndex = ref(0); // Tracks the current image index
const loading = ref(false); // Shows loading indicator during transitions
const swipeOffset = ref(0); // Tracks the swipe offset for the current swipe

// Variables to store touch positions
let startX = 0;

// Touch start event
const onTouchStart = (event) => {
  startX = event.touches[0].clientX;
  swipeOffset.value = 0; // Reset swipe offset at start
};

// Touch move event
const onTouchMove = (event) => {
  if (props.images.length == 0) return;

  const currentX = event.touches[0].clientX;
  const deltaX = currentX - startX;

  // Apply swipe offset to show next/previous image coming in
  swipeOffset.value = deltaX * 1.5; // Adjust 0.3 factor for smoother/faster preview of next image
};

// Touch end event
const onTouchEnd = (event) => {
  const endX = event.changedTouches[0].clientX;
  const deltaX = endX - startX;

  if (loading.value) return; // Prevent swipe during loading

  loading.value = true; // Show loading overlay

  setTimeout(() => {
    if (deltaX > 50) {
      // Swipe Right - Go to previous image or loop to last image
      currentIndex.value =
        currentIndex.value > 0
          ? currentIndex.value - 1
          : props.images.length - 1;
    } else if (deltaX < -50) {
      // Swipe Left - Go to next image or loop to first image
      currentIndex.value =
        currentIndex.value < props.images.length - 1
          ? currentIndex.value + 1
          : 0;
    }
    swipeOffset.value = 0; // Reset swipe offset after swipe ends
    loading.value = false; // Hide loading overlay
  }, 500); // Loading delay (adjust as needed)
};

// Directly navigate to a specific slide on dot click
const goToSlide = (index) => {
  if (loading.value) return;
  loading.value = true;
  currentIndex.value = index;
  swipeOffset.value = 0;
  setTimeout(() => {
    loading.value = false;
  }, 500); // Reset loading state after transition
};

// Start the auto-slide when the component is mounted
onMounted(() => {});

// Cleanup the interval when the component is unmounted
onBeforeUnmount(() => {});
</script>

<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #fff;
}

.top-buttons {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 2;
}

.back-btn,
.share-btn {
  background-color: white;
  color: black;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  border: 0px;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

.carousel-image {
  width: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
</style>

<!-- <template>
  <div class="carousel-wrapper mb-4">
    <TopButtons :buttons="['backbtn', 'sharebtn']" />

    <button class="carousel-btn left-btn" @click="prevImage" 
    v-if="platform === 'web'">‹</button>
    <img :src="currentImage" class="carousel-image" :alt="altText" @touchstart="startTouch"
      @touchend="endTouch" />
    <button class="carousel-btn right-btn" @click="nextImage" 
    v-if="platform === 'web'">›</button>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from 'vue';
import TopButtons from '../common/TopButtons.vue';
import { Device } from "@capacitor/device";

const platform = ref(null);
onMounted(async () => {  
  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }
})

const currentImage = computed(() => {
    if (props.images?.length > 0)
        return props.images[currentIndex.value]
    else
        return "https://via.placeholder.com/300x400";
});


const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
  altText: {
    type: String,
    default: 'Product Image',
  },
});

const currentIndex = ref(0);
const touchStartX = ref(0);

// Handle touch start
const startTouch = (event) => {
  touchStartX.value = event.touches[0].clientX;
};

// Handle touch end
const endTouch = (event) => {
  const touchEndX = event.changedTouches[0].clientX;
  const swipeDistance = touchStartX.value - touchEndX;

  const swipeThreshold = 20;
  if (swipeDistance > swipeThreshold) {
    nextImage();
  } else if (swipeDistance < -swipeThreshold) {
    prevImage();
  }
};

// Go to next image
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % props.images.length;
};

// Go to previous image
const prevImage = () => {
  currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length;
};

</script>

<style scoped>
.carousel-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.top-buttons {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 2;
}

.back-btn,
.share-btn {
  background-color: white;
  color: black;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  border: 0px;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

.carousel-image {
  width: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
}

.left-btn {
  left: 10px;
}

.right-btn {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.back-btn,
.share-btn {
  background-color: white;
  color: black;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

.back-btn i,
.share-btn i {
  font-size: 18px;
}


@media (max-width: 576px) {
  .carousel-image {
    aspect-ratio: 3 / 4;
  }

  .carousel-btn {
    padding: 8px;
    font-size: 20px;
  }

  .back-btn,
  .share-btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
</style>

 -->
