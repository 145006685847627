<template>
  <div class="container checkout-page">
    <TopButtons :buttons="['backbtn', 'title']" :options="topvarOptions" />
    <!-- Carousel for Product Items -->
    <ProductCarousel :products="products" class="mt-2" />

    <!-- Address Section -->
    <div class="my-3">
      <!-- Gift Option -->
      <div class="d-flex align-items-center mb-3" @click="showModal('gift')">
        <i class="fa fa-gift me-2"></i>
        <span class="ms-2">Send as a gift</span>
        <i class="fa fa-chevron-right ms-auto"></i>
      </div>

      <!-- Address Option -->
      <div class="d-flex align-items-center mb-3" @click="showModal('address')">
        <i class="fa fa-map-marker-alt me-2"></i>
        <span class="ms-2">{{
          address.address1 == ""
            ? "Add delivery location"
            : address.address1 +
              "," +
              address.address2 +
              "," +
              address.city +
              ","
        }}</span>
        <i class="fa fa-chevron-right ms-auto"></i>
      </div>
    </div>

    <!-- Mobile Number Section -->
    <div class="d-flex align-items-center pb-3">
      <i class="fa fa-phone me-2"></i>
      <PhoneNumberInput v-model:phonenumber="phonenumber" />
    </div>
    <!-- Email Number Section -->
    <div class="d-flex align-items-center pb-3">
      <i class="fa-solid fa-envelope me-2"></i>
      <input
        class="form-control"
        v-model="email"
        type="email"
        placeholder="Enter email address"
      />
    </div>
    <div class="d-flex align-items-center pb-3">
      <i class="fa-regular fa-clock"></i>
      <span class="ms-2">Delivery</span>
    </div>
    <!-- Delivery Options -->
    <DeliveryOptions
      :deliveryOptions="deliveryOptions"
      v-model="selectedDeliveryOption"
    />
    <hr />
    <!-- Promo Code Input -->
    <div class="input-group my-3">
      <input
        type="text"
        v-model="promoCode"
        class="form-control"
        placeholder="Enter Discount Code"
        :class="{ 'is-invalid': errorMessage }"
      />
      <button class="btn btn-dark" @click="applyPromoCode" :disabled="loading">
        Apply
      </button>
    </div>
    <div v-if="errorMessage" class="text-danger mt-2">
      {{ errorMessage }}
    </div>
    <div v-if="appliedCodes" class="mt-3">
      <span
        class="badge bg-black me-2"
        v-for="code in appliedCodes"
        v-bind:key="code"
      >
        {{ code.code }}
        <button
          type="button"
          class="btn-close btn-close-white ms-2"
          @click="removePromoCode(code)"
        ></button>
      </span>
    </div>

    <!-- Summary Section -->
    <OrderSummary :order="orderSummary" class="mt-3" />

    <!-- Payment Details -->
    <!-- <PaymentDetails
      :selectedMethod="selectedPaymentMethod"
      @select="setPaymentMethod"
    /> -->

    <!-- Return Policy Section -->
    <ReturnPolicy :policies="policies" v-if="policies.length > 0" />

    <div
      class="mx-2 mb-2 mt-2"
      style="color: red"
      v-for="error in errors"
      v-bind:key="error"
    >
      <span>{{ error }}</span> <br />
    </div>

    <!-- Buy Now Button -->
    <div class="mx-3 mt-2">
      <button
        class="btn btn-dark w-100"
        style="border-radius: 50px; padding: 1rem"
        @click="buyNow"
      >
        Buy Now
      </button>
    </div>
    <!-- Reusable Modals -->
    <GiftDetailsForm
      :isVisible="isModalVisible && showingModal == 'gift'"
      :closeModal="closeModal"
      @saveGift="saveGift"
    />
    <AddressDetailsForm
      :addressList="addressList"
      :cities="cities"
      :isVisible="isModalVisible && showingModal == 'address'"
      :closeModal="closeModal"
      @saveaddress="saveaddress"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import ProductCarousel from "@/components/checkout/ProductCarousel.vue";
// import AddressSection from "@/components/checkout/AddressSection.vue";
import DeliveryOptions from "@/components/checkout/DeliveryOptions.vue";
import OrderSummary from "@/components/checkout/OrderSummary.vue";
// import PaymentDetails from "@/components/checkout/PaymentDetails.vue";
import { useCartStore } from "@/stores/cartStore";
// import OptionItem from "@/components/checkout/OptionItem.vue";
// import ReusableModal from "@/components/checkout/ReusableModal.vue";
import GiftDetailsForm from "@/components/checkout/GiftDetailsForm.vue";
import AddressDetailsForm from "@/components/checkout/AddressDetailsForm.vue";
import PhoneNumberInput from "@/components/checkout/PhoneNumberInput.vue";
import { PromoService } from "@/services/promoservice";
import { URLService } from "@/services/_url";
import { CityService } from "@/services/cityservice";
import ReturnPolicy from "@/components/product/ReturnPolicy.vue";
import { useVendorStore } from "@/stores/vendorStore";
import TopButtons from "@/components/common/TopButtons.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { BookingService } from "@/services/bookinservice";
const userouter = useRouter();

const usevendorStore = useVendorStore();
const userstore = useUserStore();
const cartStore = useCartStore();
const policies = ref([]);
const topvarOptions = ref({ title: "CHECKOUT" });

// START ORDER REGION
const order = ref({});
const products = ref([]);
const gift = ref({
  giftfrom: "",
  giftfor: "",
  telephone: "",
  note: "",
});
const address = ref({
  name: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  province: "",
  postalcode: "",
  district: "",
});
const phonenumber = ref("");
const email = ref("");
const selectedDeliveryOption = ref(null);

// END ORDER REGION

// START MODAL DATA
const isModalVisible = ref(false);
const showingModal = ref(false);
const addressList = ref([]);
const showModal = (filterType) => {
  if (filterType == "address") {
    let a = userstore.user?.addresses;
    if (a == undefined || a == null || a == "") {
      addressList.value = [];
    } else {
      addressList.value = JSON.parse(a);
    }
  }
  showingModal.value = filterType;
  isModalVisible.value = true;
};
const closeModal = () => {
  showingModal.value = "";
  isModalVisible.value = false;
};

const saveGift = (data) => {
  gift.value = data;
};
const saveaddress = (data) => {
  address.value = data;
  closeModal();
};
// END MODAL DATA

// START PROMO AND Order Summary

const promoCode = ref("");
const appliedCodes = ref([]);
const errorMessage = ref("");
const loading = ref(false);

const applyPromoCode = async () => {
  errorMessage.value = "";
  loading.value = true;

  try {
    const response = await PromoService.validatepromo(URLService.checkpromo, {
      promo_code: promoCode.value,
    });
    response.data.code = promoCode.value;
    appliedCodes.value.push(response.data);
    promoCode.value = ""; // Clear input field
    calculatePromos();
  } catch (error) {
    errorMessage.value = error.response.data.message || "Something went wrong.";
  } finally {
    loading.value = false;
  }
};

const removePromoCode = (code) => {
  let codeind = appliedCodes.value.indexOf(code);
  appliedCodes.value.splice(codeind, 1);
  calculatePromos();
};

const calculatePromos = () => {
  if (appliedCodes.value.length > 0) {
    let _total = 0;
    let vendorPro = appliedCodes.value.filter((x) => {
      return x.vendorid != undefined && x.vendorid != null && x.vendorid != "";
    });
    let generalPro = appliedCodes.value.filter((x) => {
      return x.vendorid == undefined || x.vendorid == null || x.vendorid == "";
    });

    let vendorProductIds = [];
    vendorPro.forEach((vendor) => {
      let _products = products.value.filter((x) => {
        return x.vendor_id == vendor.vendorid;
      });
      if (_products.length > 0) {
        let _t = _products
          .map((x) => x.linetotal)
          .reduce(function (a, b) {
            return a + b;
          });
        if (vendor.discountamo == null || vendor.discountamo == undefined)
          _total += (_t * vendor.discountper) / 100;
        else _total += vendor.discountamo;

        _products.forEach((p) => vendorProductIds.push(p));
      }
    });

    generalPro.forEach((vendor) => {
      let _products = products.value.filter(
        (x) => vendorProductIds.indexOf(x) < 0
      );
      if (_products.length > 0) {
        let _t = _products
          .map((x) => x.linetotal)
          .reduce(function (a, b) {
            return a + b;
          });
        if (vendor.discountamo == null || vendor.discountamo == undefined)
          _total += (_t * vendor.discountper) / 100;
        else _total += vendor.discountamo;
      }
    });
    orderSummary.value.promoAmount = _total;
    orderSummary.value.total -= orderSummary.value.promoAmount;
  } else {
    orderSummary.value.total += orderSummary.value.promoAmount;
    orderSummary.value.promoAmount = 0;
  }
};


const getvendorPromos = (vendorid) => {
  if (appliedCodes.value.length > 0) {
    let _total = 0;
    let vendorPro = appliedCodes.value.filter((x) => {
      return x.vendorid == vendorid;
    });

    let code = "";
    let vendorProductIds = [];
    vendorPro.forEach((vendor) => {
      code += "," + vendor.code;
      let _products = products.value.filter((x) => {
        return x.vendor_id == vendor.vendorid;
      });
      if (_products.length > 0) {
        let _t = _products
          .map((x) => x.linetotal)
          .reduce(function (a, b) {
            return a + b;
          });
        if (vendor.discountamo == null || vendor.discountamo == undefined)
          _total += (_t * vendor.discountper) / 100;
        else _total += vendor.discountamo;

        _products.forEach((p) => vendorProductIds.push(p));
      }
    });
    return { codes: code, amount: _total };
  }
};
const calculatePricing = () => {
  products.value.forEach((product) => {
    orderSummary.value.subtotal += product.linetotal - product.linediscount;
    // orderSummary.deliveryFee += product.linetotal
    orderSummary.value.discount += product.linediscount;
    // orderSummary.value.taxes += product.linediscount
    orderSummary.value.total += product.linetotal;
  });
  calculatePromos();
};

//END PROMO

// START Delivery

const cities = ref([]);

watch(selectedDeliveryOption, (newval, oldval) => {
  orderSummary.value.deliveryFee += newval.price - (oldval?.price ?? 0);
  orderSummary.value.total += newval.price - (oldval?.price ?? 0);
});

const deliveryOptions = ref([
  { name: "Standard Delivery", price: 300, description: "1-3 days" },
  {
    name: "Flash Delivery",
    price: 600,
    description: "Same-day delivery if order placed before 5 PM",
  },
]);

// END Delivery

const orderSummary = ref({
  subtotal: 0,
  deliveryFee: 0,
  discount: 0,
  taxes: 0,
  total: 0,
  promoAmount: 0,
  finaltotal: 0,
});

const showerrors = ref(false);
const errors = ref([]);

const buyNow = async () => {
  showerrors.value = false;
  if ((await validation()) == true) {
    try {
      let url = URLService.createbooking;

      let promos = appliedCodes.value.map((x) => x.code).join(",");
      let data = {
        customer_id: userstore.user?.id ?? null,
        customername: gift.value.giftfrom
          ? gift.value.giftfrom
          : userstore.user?.name
          ? userstore.user?.name
          : "Guest ",
        email: email.value,
        phone: phonenumber.value,
        address:
          address.value.address1 +
          "," +
          address.value.address2 +
          "," +
          address.value.city +
          ",(" +
          address.value.postalcode +
          ")",
        province: address.value.district,
        from: gift.value.giftfrom ?? "",
        to: gift.value.giftfor ?? "",
        giftphone: gift.value.telephone ?? "",
        note: gift.value.note ?? "",
        delivery_option: selectedDeliveryOption.value.name,
        delivery_cost: orderSummary.value.deliveryFee,
        promo_code: promos,
        promo_discount: orderSummary.value.promoAmount,
        subtotal: orderSummary.value.subtotal,
        discount: orderSummary.value.discount,
        tax: orderSummary.value.taxes,
        net_total: orderSummary.value.total,
      };
      let items = [];
      products.value.forEach((element) => {
        items.push({
          vendor_id: element.vendor_id,
          product_id: element.itemid,
          product_name: element.name,
          image_url: element.image,
          color: element.color,
          size: element.size,
          quantity: element.quantity,
          location_id: element.locationid,
          selling_price: element.itemprice,
          discounted_price: element.linediscount,
          used_price_for_calc:
            element.linediscount > 0 ? element.linediscount : element.itemprice,
          item_total: element.linetotal,
          promocodes: getvendorPromos(element.vendor_id)?.codes,
          promoamount: getvendorPromos(element.vendor_id)?.amount ?? 0,
        });
      });
      data.items = items;

      let response = await BookingService.createbooking(url, data);
      if (response.status >= 200 || response.status < 400) {
        // DO the booking
        userouter.push({ name: "paymentpage" });
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    showerrors.value = true;
  }
};

const validation = () => {
  let validation = true;
  errors.value = [];
  // Validation
  if (phonenumber.value == "" || phonenumber.value == undefined) {
    validation = false;
    errors.value.push("Please add a valid phone number");
  }
  if (email.value == "" || phonenumber.value == undefined) {
    validation = false;
    errors.value.push("Please add a valid email address");
  } else if (!validateEmail(email.value)) {
    validation = false;
    errors.value.push("Please add a valid email address");
  }
  if (address.value.address1 == "" || address.value.address1 == undefined) {
    validation = false;
    errors.value.push("Please set a delivery address (Address Line 1)");
  }
  if (address.value.city == "" || address.value.city == undefined) {
    validation = false;
    errors.value.push("Please set a delivery address (City)");
  }
  if (
    selectedDeliveryOption.value == null ||
    selectedDeliveryOption.value == undefined
  ) {
    validation = false;
    errors.value.push("Please set a delivery option");
  }
  return validation;
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

onMounted(async () => {
  let vendorIds = [];
  await cartStore.loadCartFromLocalStorage();
  cities.value = (await CityService.getallcities()).sort((a, b) =>
    a.city > b.city ? 1 : -1
  );
  let cart = JSON.parse(JSON.stringify(cartStore.cart));
  if (cart.length > 0) {
    let items = [];
    cart.forEach((item) => {
      vendorIds.push(item.vendor_id);
      items.push({
        itemid: item.id,
        name: item.name,
        locationid: item.location.id,
        size: item.size,
        color: item.color,
        quantity: item.quantity,
        image: item?.images?.[0],
        itemprice:
          item.discount && item.discount > 0 ? item.discount : item.price,
        linetotal:
          (item.discount && item.discount > 0 ? item.discount : item.price) *
          item.quantity,
        linediscount:
          (item.discount && item.discount > 0
            ? item.discount - item.price
            : 0) * item.quantity,
        vendor_id: item.vendor_id,
      });
      return;
    });

    products.value = items;
    calculatePricing();
    order.value.id = "";
  }

  let vendorPolicies = usevendorStore.policies.filter((x) => {
    return vendorIds.indexOf(x.vendor_id) >= 0;
  });
  if (vendorPolicies.length > 0) policies.value = vendorPolicies;
});
</script>

<style>
.w-full {
  width: 100% !important;
}
.input-group .is-invalid {
  border-color: red;
}
.bg-black {
  background-color: black;
  font-size: medium !important;
}
/* Add any additional styling here */
.checkout-page {
  padding: 10px;
  overflow-y: auto;
  height: calc(100vh);
  padding-bottom: 8vh;
}
.checkout-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.checkout-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
