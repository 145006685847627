import axios from 'axios'

// const baseurl = import.meta.env.VITE_APP_BaseURL
// axios.defaults.baseURL = baseurl

export const PromoService = {
  async validatepromo(url, request) {
    return await axios.post(url, request)
  }
}
